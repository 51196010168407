import * as THREE from 'three'
import { cloneDeep } from 'lodash'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'
import { TransformControls } from '../controls/transformControls'
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter'
import Measure from '../Measure/Measure'
import Cut from '../Cut/cut'
import Crop from '../crop/crop.js'
import History from '../history/history'
import ReduxStore from '../../../Redux/Store'

export const controlMode = {
  MEASURE: 'measure',
  SCALE: 'scale',
  CROP: 'crop',
  CUT: 'cut',
  FILL: 'fill',
  HOLLOW: 'hollow',
}
export class threedManager {
  constructor() {
    this.scene = undefined
    this.camera = undefined
    this.renderer = undefined
    this.mainObject = undefined
    this.INTERSECTED = null
    this.resultMesh = undefined
    this.controlMode = ''
    this.domain = 'http://localhost:3000'
    this.loadState = 'preload'
    // 'preload'
    // 'loading'
    // 'loaded'

    this.brush = { add: true, size: 1 }

    this.Materials = {
      tooth: new THREE.MeshPhongMaterial({
        color: 0xaaaaaa,
        side: THREE.DoubleSide,
        transparent: false,
        flatShading: true,
      }),
    }

    this.initScene()
    this.costomizeRenderer()
    this.initLight()

    this.addEventListeners()
    this.render()

    this.app = new window.SculptGL()
    this.app.start()
  }

  initScene() {
    this.scene = new THREE.Scene()
    const width = window.innerWidth
    const height = window.innerHeight
    this.camera = new THREE.PerspectiveCamera(30, width / height, 0.1, 10000)
    this.scene.add(this.camera)
    let canvas = document.getElementById('canvas3d')
    this.renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      physicallyCorrectLights: true,
    })
    this.renderer.setSize(width, height)
    this.renderer.setClearColor(0xffffff)
    this.renderer.shadowMap.enabled = true

    this.controls = new TrackballControls(this.camera, this.renderer.domElement)
    this.controls.rotateSpeed = 1.5
    this.controls.update()
    this.controls.addEventListener('change', (e) => {
      if (this.Measure && this.Measure.active && this.camera && this.mainObject) {
        let distanceToObject = this.camera.position.distanceTo(this.mainObject.position)
        this.Measure.changeScaleOfPoint(distanceToObject)
        this.Measure.adjustDimsVisibility()
      }
    })

    this.Measure = new Measure(this.scene, this.renderer, this.camera, this.controls)

    this.cylinder = new THREE.Mesh(
      new THREE.CylinderGeometry(5, 5, 20, 32),
      new THREE.MeshPhongMaterial({ color: 0x0080ff })
    )
    this.cylinder.matrixAutoUpdate = true
    this.cylinder.visible = false
    this.cylinder.radius = 5
    this.cylinder.length = 20
    this.scene.add(this.cylinder)
    this.initTransformCtrl()

    this.cutByPlane = new Cut(this.scene, this.camera, this.renderer.domElement, this.controls)
    this.Crop = new Crop(this)
    this.Crop.deactivate()

    this.history = new History(this)
  }

  initTransformCtrl = () => {
    this.transformCtrl = new TransformControls(this.camera, this.renderer.domElement)
    this.scene.add(this.transformCtrl)
    this.transformCtrl.visible = false
    this.transformCtrl.enabled = false
    this.transformCtrl.setSize(2)
    this.transformCtrl.setSpace('local')
    this.transformCtrl.name = 'transformCtrl'

    this.transformCtrl.addEventListener('mouseDown', (e) => {
      this.controls.enabled = false
    })
    this.transformCtrl.addEventListener('mouseUp', (e) => {
      this.controls.enabled = true
      this.mainObject.updateMatrix()
      this.mainObject.geometry.applyMatrix(this.mainObject.matrix)
      this.mainObject.matrix.identity()
      this.mainObject.geometry.center()
      this.mainObject.rotation.set(0, 0, 0)

      if (this.controlMode === controlMode.MEASURE) this.measureMode()
      else if (this.controlMode === controlMode.CROP) this.cropMode()
      this.transformCtrl.enabled = true
      this.transformCtrl.visible = true
    })
  }

  initLight() {
    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.7)
    this.scene.add(hemiLight)

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.2)
    this.scene.add(ambientLight)

    const pointLight = new THREE.PointLight(0xffffff, 0.3)
    pointLight.castShadow = true
    this.camera.add(pointLight)
  }

  render = () => {
    window.requestAnimationFrame(this.render)
    this.controls.update()
    this.renderer.render(this.scene, this.camera)
    this.renderer.clearDepth()
    if (this.Measure) {
      this.Measure.animate()
    }
  }

  costomizeRenderer() {
    let ele = this.renderer.domElement
    ele.className = 'mainRenderer'
  }

  addEventListeners() {
    var _this = this
    window.addEventListener(
      'resize',
      () => {
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.updateProjectionMatrix()

        this.renderer.setSize(window.innerWidth, window.innerHeight)
      },
      false
    )

    var drag = 0
    var mousedown = false
    this.renderer.domElement.addEventListener(
      'pointerdown',
      (event) => {
        mousedown = true
        drag = 0
      },
      false
    )

    _this.renderer.domElement.addEventListener(
      'pointermove',
      (event) => {
        if (mousedown) drag++
        if (_this.loadState !== 'loaded') return
        if (_this.controlMode === controlMode.MEASURE && _this.Measure) {
          _this.Measure.onmousemove(event)
        }
      },
      false
    )

    _this.renderer.domElement.addEventListener(
      'pointerup',
      (event) => {
        if (_this.loadState !== 'loaded') return

        if (drag < 5) {
          if (_this.controlMode === controlMode.MEASURE && _this.mainObject) {
            _this.Measure.mouseup(event)
            drag = 0
          }
        }
        drag = 0
        mousedown = false
      },
      false
    )

    _this.renderer.domElement.addEventListener('contextmenu', (event) => {
      event.preventDefault()
    })

    _this.renderer.domElement.addEventListener('dragenter', () => {
      this.renderer.setClearColor(0x808e99)
    })

    _this.renderer.domElement.addEventListener('dragleave', () => {
      this.renderer.setClearColor(0xffffff, 1)
    })

    _this.renderer.domElement.addEventListener('drop', () => {
      this.renderer.setClearColor(0xffffff, 1)
    })
  }

  setcontent(object = this.mainObject, initialize = true) {
    if (!object) return
    // put a camera at a proper position and look at a object
    this.box = new THREE.Box3().setFromObject(object)
    const size = this.box.getSize(new THREE.Vector3()).length()
    this.controls.reset()
    this.camera.position.set(0, 0, size * 2)
    this.camera.updateProjectionMatrix()
    if (initialize) {
      this.Measure.init(object, initialize)
      this.Crop.init(object, initialize)
    }
  }

  loadMesh(file) {
    this.file = file
    var _this = this
    let reader = new FileReader()
    let loader = new STLLoader()
    reader.onload = function (event) {
      let result = event.target.result
      let geometry = loader.parse(result)

      var mesh = new THREE.Mesh(geometry, _this.Materials.tooth.clone())
      mesh.name = 'object'

      _this.mainObject = mesh
      _this.scene.add(mesh)
      this.loadState = 'loaded'
      ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
      _this.setcontent(mesh)
    }
    reader.readAsArrayBuffer(file)
  }

  async loadObjFiles(buffers) {
    const obj = buffers[0]
    const jpg = buffers[2]
    const objloader = new OBJLoader()
    const textureloader = new THREE.TextureLoader()

    var userImageURL = window.URL.createObjectURL(new Blob([jpg], { type: 'image/jpg' }))
    textureloader.setCrossOrigin('')
    var texture = textureloader.load(userImageURL)
    let object = await objloader.parse(obj)
    this.mainObject = object.children[0]
    this.mainObject.material.map = texture
    this.mainObject.name = 'object'
    this.scene.add(this.mainObject)
    this.setcontent(this.mainObject)
    this.loadState = 'loaded'
    ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
    this.sendToSculpt(this.mainObject)
  }

  async loadObjFiles_firebase(files) {
    const obj = files.filter((f) => f.type === 'obj')[0]
    const diffuseImage = files.filter(
      (f) => (f.type === 'png' || f.type === 'jpg') && f.url.includes('diffuse')
    )[0]
    const normalImg = files.filter((f) => f.type === 'png' || f.type === 'jpg')[0]
    const img = diffuseImage ?? normalImg
    if (!obj || !img) {
      ReduxStore.dispatch({ type: 'setLoadState', data: 'preload' })
      console.error('files are missing')
      return
    }
    const objloader = new OBJLoader()
    const textureloader = new THREE.TextureLoader()

    objloader.load(obj.url, (obj) => {
      this.mainObject = obj.children[0]
      textureloader.load(img.url, (texture) => {
        this.mainObject.material.map = texture
        this.mainObject.name = 'object'
        // console.log('Scale Object')
        // this.mainObject.scale.multiplyScalar(1000)
        this.scene.add(this.mainObject)
        this.setcontent(this.mainObject)
        this.loadState = 'loaded'
        ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
        this.sendToSculpt(this.mainObject)
      })
    })
  }

  loadFile(file, fileType) {
    this.file = file
    let geometry, loader, mesh
    this.clearObjects()
    try {
      switch (fileType) {
        case 'stl':
          loader = new STLLoader()
          geometry = loader.parse(file)
          geometry.center()
          mesh = new THREE.Mesh(geometry, this.Materials.tooth.clone())
          mesh.name = 'object'
          this.mainObject = mesh
          this.scene.add(mesh)
          this.loadState = 'loaded'
          // ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' });
          this.setcontent(mesh)
          try {
            this.app.loadScene(file, fileType)
          } catch {
            alert('Cannot load the model in the sculpt app.')
          }
          break
        case 'glb' || 'gltf':
          loader = new GLTFLoader()
          loader.parse(file, '', (gltf) => {
            try {
              mesh = new THREE.Mesh(
                gltf.scene.children[0].geometry,
                gltf.scene.children[0].material
              )
              mesh.geometry.rotateX(Math.PI / 2)
              mesh.material.metalness = 0.2
              mesh.material.roughness = 0.2
              mesh.name = 'object'
              this.mainObject = mesh
              this.scene.add(mesh)
              this.loadState = 'loaded'
              ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
              this.setcontent(mesh)
              this.sendToSculpt(mesh)
              this.Measure.init(mesh, true)
              this.Crop.init(mesh, true)
            } catch (err) {
              console.log(err.message)
              alert('Unable to read gltf file. Please try another files')
            }
          })
          break
        default:
          break
      }
    } catch {
      alert('Corrupted File. Please input other files.')
      this.loadState = 'preload'
      ReduxStore.dispatch({ type: 'setLoadState', data: 'preload' })
    }
  }

  loadFileFromUrl(url) {
    let loader = new STLLoader()
    try {
      loader.load(url, (geometry) => {
        if (this.mainObject.geometry instanceof THREE.Geometry) this.mainObject.geometry.dispose()
        this.mainObject.geometry = geometry
        this.mainObject.material.dispose()
        this.mainObject.material = this.Materials.tooth.clone()
        this.mainObject.geometry.center()
        this.setcontent(this.mainObject)
        this.loadState = 'loaded'
        ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
        this.Crop.init(this.mainObject)
        this.Measure.init(this.mainObject)
      })
    } catch {
      alert('Corrupted File. Please input other files.')
      this.loadState = 'preload'
      ReduxStore.dispatch({ type: 'setLoadState', data: 'preload' })
    }
  }

  clearObjects() {
    if (!this.mainObject) return
    this.transformCtrl.detach(this.mainObject)
    this.transformCtrl.enabled = false
    this.transformCtrl.visible = false
    if (this.mainObject.geometry instanceof THREE.Geometry) this.mainObject.geometry.dispose()
    this.mainObject.material.dispose()
    this.scene.remove(this.mainObject)
    this.mainObject = null

    this.Measure.object = null
    this.Crop.object = null
    this.Measure.deactivate()
    this.Crop.deactivate()
    this.cylinder.visible = false
    this.loadState = 'preload'
    ReduxStore.dispatch({ type: 'setLoadState', data: 'preload' })
    if (this.controlMode === controlMode.MEASURE) this.controls.enabled = false

    this.controlMode = ''

    let clearBtn =
      document.getElementsByClassName('gui-topbar')[0].children[0].children[1].children[0]
        .children[0]
    clearBtn.click()

    const viewPort = document.getElementById('viewport')
    viewPort.style.zIndex = 2
  }

  removeCutGadgets() {
    this.cutByPlane.hideCutPlane()
  }

  convertToBuffergeometry(geometry) {
    if (geometry.isBufferGeometry) return geometry
    return new THREE.BufferGeometry().fromGeometry(geometry)
  }

  convertToGeometry(geometry) {
    if (!geometry.isBufferGeometry) return geometry
    return new THREE.Geometry().fromBufferGeometry(geometry)
  }

  sendToSculpt(object) {
    this.loadState = 'loaded'
    ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
    try {
      var exporter = new STLExporter()

      if (object === undefined || object === null) {
        console.error('The main object is null or undefined')
        return
      }
      object.geometry.center()

      const result = exporter.parse(object, { binary: true })
      const data = new Blob([result])
      let url = window.URL.createObjectURL(data)
      if (url === undefined) {
        console.error('cannot create object url')
        return
      }
      let clearBtn =
        document.getElementsByClassName('gui-topbar')[0].children[0].children[1].children[0]
          .children[0]
      clearBtn.click()
      this.app.addModelURL(url)
      this.loadState = 'loaded'
      ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
    } catch (err) {
      this.loadState = 'loaded'
      ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
      console.warn(err.message)
    }
  }

  exportSTL() {
    var exporter = new STLExporter()
    let object = this.scene.getObjectByName('object')
    if (object === undefined || object === null) {
      console.log('This mesh cannot be exported because its undefined or null')
      return
    }
    const result = exporter.parse(object, { binary: true })
    this.saveArrayBuffer(result, 'result.stl')
  }

  saveArrayBuffer(buffer, filename) {
    this.save(new Blob([buffer], { type: 'application/octet-stream' }), filename)
  }

  save(blob, filename) {
    const link = document.createElement('a')
    link.style.display = 'none'
    document.body.appendChild(link)

    link.href = URL.createObjectURL(blob)
    link.download = filename
    link.click()
  }

  initControl(isScale = false) {
    if (!this.mainObject) return
    this.controls.enabled = true
    this.Measure.deactivate()
    this.Crop.deactivate()
    this.controlMode = ''
    this.mainObject.material.transparent = false
    this.mainObject.material.opacity = 1.0

    this.cylinder.visible = false
    if (this.transformCtrl) {
      this.transformCtrl.enabled = false
      this.transformCtrl.visible = false
    }
  }

  viewMode() {
    this.initControl()
    this.transformCtrl.enabled = false
    this.transformCtrl.visible = false
  }

  measureMode() {
    this.initControl()
    this.controlMode = controlMode.MEASURE
    this.Measure.init(this.mainObject)
    this.Measure.activate()
  }

  remesh() {
    if (this.loadState !== 'loaded' || this.mainObject === undefined) return
    let _this = this
    this.initControl()
    ReduxStore.dispatch({ type: 'setLoadState', data: 'loading' })
    document.getElementsByClassName('gui-button')[10].click()
    let myInterval = setInterval(() => {
      if (localStorage.getItem('modelUrl') !== '') {
        try {
          _this.loadFileFromUrl(localStorage.getItem('modelUrl'))
          localStorage.setItem('modelUrl', '')
          ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
          clearInterval(myInterval)
        } catch {
          alert('Sorry. You cannot save the model.')
        }
      }
    }, 200)

    this.controlMode = controlMode.FILL
    this.mainObject.remeshed = true
  }

  hollowMode() {
    this.cutByPlane.hideCutPlane()

    if (this.loadState !== 'loaded' || this.mainObject === undefined) return
    if (!this.mainObject.remeshed) {
      alert('Please convert the model to be able to use Hollow function.')
      return
    }
    this.initControl()
    this.controlMode = controlMode.HOLLOW
    this.mainObject.material.transparent = true
    this.mainObject.material.opacity = 0.6

    this.cylinder.visible = true
    this.transformCtrl.attach(this.cylinder)
    this.transformCtrl.enabled = true
    this.transformCtrl.visible = true
  }

  changeRadius(r) {
    const length = this.cylinder.length
    this.cylinder.radius = r
    this.updateGeometry(this.cylinder, new THREE.CylinderGeometry(r, r, length, 32))
  }

  changeLength(l) {
    this.cylinder.length = l
    const radius = this.cylinder.radius
    this.updateGeometry(this.cylinder, new THREE.CylinderGeometry(radius, radius, l, 32))
  }

  updateGeometry(mesh, geometry) {
    if (mesh.geometry instanceof THREE.Geometry) mesh.geometry.dispose()

    mesh.geometry = geometry
  }

  hollow(undoAction = false) {
    if (this.loadState !== 'loaded' || this.mainObject === undefined) return

    this.loadState = 'loading'
    ReduxStore.dispatch({ type: 'setLoadState', data: 'loading' })
    setTimeout(() => {
      let geo
      if (
        this.mainObject.geometry instanceof THREE.BufferGeometry ||
        this.mainObject.geometry.isBufferGeometry
      ) {
        geo = new THREE.Geometry().fromBufferGeometry(this.mainObject.geometry)
        this.mainObject.geometry.dispose()
        this.mainObject.geometry = geo
      }
      if (!geo) {
        console.log('Geometry1 error')
        return
      }

      var geom1 = new window.ThreeBSP(this.mainObject)
      var geom2 = new window.ThreeBSP(this.cylinder)
      geom1.subtract(geom2).then((geo) => {
        const oldGeometry = cloneDeep(this.mainObject.geometry)
        if (this.mainObject.geometry instanceof THREE.Geometry) this.mainObject.geometry.dispose()
        this.mainObject.geometry = geo.toGeometry()

        this.initControl()

        var exporter = new STLExporter()
        let object = this.scene.getObjectByName('object')
        if (object === undefined || object === null) {
          console.error('The main object is null or undefined')
          return
        }
        const result = exporter.parse(object, { binary: true })
        const data = new Blob([result])
        let url = window.URL.createObjectURL(data)
        if (url === undefined) {
          console.error('cannot create object url')
          return
        }
        let clearBtn =
          document.getElementsByClassName('gui-topbar')[0].children[0].children[1].children[0]
            .children[0]
        clearBtn.click()
        this.app.addModelURL(url)

        this.loadState = 'loaded'
        ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })

        const updateItem = {
          category: 'hollow',
          value: oldGeometry,
        }
        if (!undoAction) this.history.execute(updateItem)

        this.Crop.init(this.mainObject)
        this.Measure.init(this.mainObject)
      })
    }, 300)
  }

  fillAddMode(flag) {
    this.brush.add = flag
    if (flag) {
      this.app._gui._ctrlSculpting.onKeyUp('Alt')
    } else {
      this.app._gui._ctrlSculpting.onKeyDown('Alt')
    }
  }

  setBrush(value) {
    this.brush.size = value
    // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    var isSafari = window.isSafari
    if (isSafari === undefined) {
      console.warn('Unalbe to detect safari browser.')
      isSafari = false
    }

    try {
      switch (value) {
        case 1:
          this.app._sculptManager._tools[0]._radius = isSafari ? 150 : 30
          this.app._sculptManager._tools[0]._intensity = isSafari ? 0.6 : 0.3
          break
        case 2:
          this.app._sculptManager._tools[0]._radius = isSafari ? 250 : 50
          this.app._sculptManager._tools[0]._intensity = isSafari ? 0.8 : 0.4
          break
        case 3:
          this.app._sculptManager._tools[0]._radius = isSafari ? 375 : 75
          this.app._sculptManager._tools[0]._intensity = isSafari ? 1.0 : 0.5
          break
        default:
          break
      }
    } catch {
      alert('Unable to adjust brush size')
    }
  }

  prepareCut() {
    // click btn event
    this.initControl()
    this.controlMode = controlMode.CUT
    if (this.mainObject) this.cutByPlane.showCutPlane(this.mainObject)
  }

  setTransformMode(mode) {
    if (mode === 'translate' || mode === 'rotate') {
      this.cutByPlane.setTransformCtrl(mode)
      this.transformCtrl.setMode(mode)
    }
  }

  //Apply Scale Function
  scale(realScale) {
    const realValue = parseInt(realScale)
    if (Number.isNaN(realValue) || !this.mainObject) return
    const str = document.getElementById('labelDiv').innerHTML
    const currentValue = parseFloat(str.substr(0, str.length - 2))
    if (Number.isNaN(currentValue) || currentValue === 0) return
    const scaleFactor = realValue / currentValue
    this.mainObject.geometry.scale(scaleFactor, scaleFactor, scaleFactor)
    this.setcontent()
    this.Measure.deactivate()
    this.Crop.init(this.mainObject, true)
    this.Measure.init(this.mainObject)
    const updateItem = {
      category: 'scale',
      value: scaleFactor,
    }
    this.history.execute(updateItem)
  }

  scaleUndo(scaleFactor) {
    if (Number.isNaN(scaleFactor) || !this.mainObject) return
    this.mainObject.geometry.scale(scaleFactor, scaleFactor, scaleFactor)
    this.Measure.deactivate()
    this.Crop.init(this.mainObject, true)
    this.Measure.init(this.mainObject)
  }

  async cutModel(undoAction = false) {
    var model = this.mainObject
    if (!model) {
      console.log('Empty model')
      return
    }
    if (this.controlMode !== controlMode.CUT) return

    this.loadState = 'loading'
    ReduxStore.dispatch({ type: 'setLoadState', data: 'loading' })
    let newGeo
    newGeo = await this.cutByPlane.mouseup(model)
    if (newGeo) {
      const oldGeometry = cloneDeep(model.geometry)
      if (model.geometry instanceof THREE.Geometry) model.geometry.dispose()

      if (newGeo instanceof THREE.Geometry || newGeo.isGeometry) {
        model.geometry = new THREE.BufferGeometry().fromGeometry(newGeo)
      } else model.geometry = newGeo

      try {
        var exporter = new STLExporter()
        if (model === undefined || model === null) {
          console.error('The main object is null or undefined')
          return
        }
        const result = exporter.parse(model, { binary: true })
        const data = new Blob([result])
        let url = window.URL.createObjectURL(data)
        if (url === undefined) {
          console.error('cannot create object url')
          return
        }
        let clearBtn =
          document.getElementsByClassName('gui-topbar')[0].children[0].children[1].children[0]
            .children[0]
        clearBtn.click()
        await this.app.addModelURL(url)
        setTimeout(() => {
          this.loadState = 'loaded'
          ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
          this.remesh()
          this.removeCutGadgets()

          this.Crop.init(this.mainObject)
          this.Measure.init(this.mainObject)
        }, 1000)

        const updateItem = {
          category: 'cut',
          value: oldGeometry,
        }
        if (!undoAction) this.history.execute(updateItem)
      } catch (err) {
        this.loadState = 'loaded'
        ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
        console.warn(err.message)
      }
    }
    return
  }

  undo() {
    if (this.controlMode === controlMode.FILL) {
      document.getElementsByClassName('shortcut')[17].click()

      let myInterval = setInterval(() => {
        if (localStorage.getItem('modelUrl') !== '') {
          try {
            this.loadFileFromUrl(localStorage.getItem('modelUrl'))
            localStorage.setItem('modelUrl', '')
            clearInterval(myInterval)
          } catch {
            alert('Sorry. You cannot save the model.')
          }
        }
      }, 200)
    } else {
      this.history.undo()
      this.sendToSculpt(this.mainObject)
    }
    this.viewMode()
  }

  crop(undoAction = false) {
    const { oldGeometry, newGeometry } = this.Crop.apply()
    if (this.mainObject.geometry instanceof THREE.Geometry) this.mainObject.geometry.dispose()
    this.mainObject.geometry = newGeometry
    const updateItem = {
      category: 'crop',
      value: oldGeometry,
    }
    if (!undoAction) {
      this.history.execute(updateItem)
    }
    this.viewMode()
  }

  cropMode() {
    this.initControl()
    this.controlMode = controlMode.CROP
    this.Crop.activate()
    this.Crop.activateClipping()
    this.Crop.init(this.mainObject, true)
  }

  cropFinish() {
    if (!this.mainObject) return
    this.remesh()
  }

  handleCropValueChange(value) {
    this.Crop.moveFace(value)
  }

  setControlMode(controlMode) {
    this.controlMode = controlMode
  }

  rotateModel() {
    if (!this.mainObject || !this.transformCtrl) return
    this.transformCtrl.attach(this.mainObject)
    this.transformCtrl.enabled = true
    this.transformCtrl.visible = true
    this.transformCtrl.setMode('rotate')
  }
}
