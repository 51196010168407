import React, { useState, useEffect, useCallback } from 'react'
import { Button, Card } from 'react-bootstrap'
import { MdClear } from 'react-icons/all'

import logo from '../../assets/img/r3dent_small.svg'
import nearImg from '../../assets/img/near.svg'
import rulerImg from '../../assets/img/ruler.svg'
import centerImg from '../../assets/img/center.svg'
import undoImg from '../../assets/img/undo.svg'
import nearWhiteImg from '../../assets/img/near_white.svg'
import rulerWhiteImg from '../../assets/img/ruler_white.svg'
import centerWhiteImg from '../../assets/img/center_white.svg'
import rotateImg from '../../assets/img/rotate.svg'
import rotateWhiteImg from '../../assets/img/rotate_white.svg'
import { connect } from 'react-redux'
import ReduxStore from '../../Redux/Store'
import {
  measureMode,
  clearScene,
  viewMode,
  centerModel,
  undo,
  rotateModel,
} from '../3dView/main/main'

import './main.css'

const Header = (props) => {
  const { onBack } = props
  const [mode, setMode] = useState('')

  const [readLocal, setReadLocal] = useState(props.localFileRead || props.loadState === 'loaded')

  useEffect(() => {
    setMode('view')
  }, [props.initHeader])

  useEffect(() => {
    setReadLocal(props.localFileRead || props.loadState === 'loaded')
  }, [props.localFileRead, props.loadState])

  const goBack = () => {
    if (onBack) {
      onBack()
    } else {
      ReduxStore.dispatch({ type: 'setLocalFileRead', data: true })
      setReadLocal(true)
    }
  }

  const clear = () => {
    if (window.confirm('Are you sure you want to clear the scene?')) {
      clearScene()
      setMode('rotate')
      props.setInitControl()
    }
  }

  const setView = (e) => {
    setMode('view')
    viewMode()
    document.getElementById('viewport').style.zIndex = 2
    props.setInitControl()
  }

  const setMeasure = useCallback(
    (e) => {
      setMode('measure')
      measureMode()
      document.getElementById('viewport').style.zIndex = 2
      if (e.isTrusted) props.setInitControl(true)
    },
    [props, setMode]
  )

  const center = (e) => {
    setMode('center')
    centerModel()
  }

  const setUndo = () => {
    setMode('undo')
    undo()
  }

  const setRotate = () => {
    setMode('rotate')
    rotateModel()
  }

  const disableModelActions = Boolean(props.loadState === 'loaded')

  return (
    <Card className="header-card">
      <Card.Body>
        <span
          className="d-none d-sm-inline-block"
          style={{ fontSize: '22px', marginTop: '-5px', marginLeft: '8px' }}
        >
          <img src={logo} alt="r3dent" className="!ml-4"></img>
        </span>
        {!(onBack || !readLocal) ? (
          <>
            <Button
              variant=""
              className="headerBtn clearBtn inactiveBtn shadow-none"
              onClick={clear}
              disabled={!disableModelActions}
            >
              <MdClear className="d-block d-sm-none" style={{ fontSize: '25px' }} />
              <span className="d-none d-sm-block">Clear</span>
            </Button>
            <Button
              variant=""
              className="headerBtn undoBtn inactiveBtn shadow-none !mx-2"
              onClick={setUndo}
              disabled={!disableModelActions}
            >
              <span className="d-none d-sm-block">Undo</span>
            </Button>
            <div className="vl"></div>
            <Button
              variant=""
              className={
                mode === 'measure'
                  ? 'headerBtn iconBtn activeBtn shadow-none'
                  : 'headerBtn iconBtn inactiveBtn shadow-none'
              }
              id="measureBtn"
              onClick={setMeasure}
              disabled={!disableModelActions}
            >
              {mode === 'measure' ? (
                <img src={rulerWhiteImg} className="img-w20" alt="measure" />
              ) : (
                <img src={rulerImg} className="img-w20" alt="measure" />
              )}
            </Button>
            <Button
              variant=""
              className={
                mode === 'rotate'
                  ? 'headerBtn iconBtn activeBtn shadow-none'
                  : 'headerBtn iconBtn inactiveBtn shadow-none'
              }
              id="rotateBtn"
              onClick={setRotate}
              disabled={!disableModelActions}
            >
              {mode === 'rotate' ? (
                <img src={rotateWhiteImg} className="img-w20" alt="rotate" />
              ) : (
                <img src={rotateImg} className="img-w20" alt="rotate" />
              )}
            </Button>
            <Button
              variant=""
              className={
                mode === 'center'
                  ? 'headerBtn iconBtn activeBtn shadow-none'
                  : 'headerBtn iconBtn inactiveBtn shadow-none'
              }
              onClick={center}
              disabled={!disableModelActions}
            >
              {mode === 'center' ? (
                <img src={centerWhiteImg} className="img-w20" alt="center" />
              ) : (
                <img src={centerImg} className="img-w20" alt="center" />
              )}
            </Button>
            <Button
              variant=""
              className={
                mode === 'view'
                  ? 'headerBtn iconBtn activeBtn shadow-none'
                  : 'headerBtn iconBtn inactiveBtn shadow-none'
              }
              onClick={setView}
              disabled={!disableModelActions}
            >
              {mode === 'view' ? (
                <img src={nearWhiteImg} className="img-w20" alt="view" />
              ) : (
                <img src={nearImg} className="img-w20" alt="view" />
              )}
            </Button>
          </>
        ) : (
          <Button
            variant=""
            className={'headerBtn iconBtn inactiveBtn shadow-none'}
            style={{ display: 'flex', width: 120 }}
            onClick={goBack}
          >
            <img src={undoImg} className="img-w20" alt="undo" />
            Go Back
          </Button>
        )}
      </Card.Body>
    </Card>
  )
}

// export default Header
function mapStateToProps(state) {
  return {
    loadState: state.LoadState,
    localFileRead: state.LocalFileRead,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLoadState: (value) => dispatch({ type: 'setLoadState', data: value }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
