// import * as THREE from 'three';
import * as THREE from '../../../../node_modules/three/build/three.module.js'
import { FacesFromEdges } from './FacesFromEdges.js'

export class GeometryBuilder {
  constructor(sourceGeometry, targetGeometry, slicePlane, matrix) {
    this.sourceGeometry = sourceGeometry
    this.targetGeometry = targetGeometry
    this.slicePlane = slicePlane
    this.addedVertices = []
    this.addedIntersections = []
    this.newEdges = [[]]
    this.FACE_KEYS = ['a', 'b', 'c']
    this.matrix = matrix
    // if(matrix){
    //     this.sourceGeometry.vertices.map(v => v.applyMatrix4(matrix));
    // }
  }

  startFace(sourceFaceIndex) {
    this.sourceFaceIndex = sourceFaceIndex
    this.sourceFace = this.sourceGeometry.faces[sourceFaceIndex]
    this.sourceFaceUvs = this.sourceGeometry.faceVertexUvs[0][sourceFaceIndex]

    this.faceIndices = []
    this.faceNormals = []
    this.faceUvs = []
  }

  endFace() {
    var indices = this.faceIndices.map(function (index, i) {
      return i
    })
    this.addFace(indices)
  }

  closeHoles() {
    var fFE = new FacesFromEdges()
    fFE.facesFromEdges(this.newEdges).forEach(function (faceIndices) {
      if (faceIndices === undefined) return
      var normal = this.faceNormal(faceIndices)
      if (normal === undefined) return
      if (normal.dot(this.slicePlane.normal) > 0.5) {
        faceIndices.reverse()
      }

      this.startFace()
      this.faceIndices = faceIndices
      this.endFace()
    }, this)
  }

  addVertex(key) {
    this.addUv(key)
    this.addNormal(key)

    var index = this.sourceFace[key]
    var newIndex

    if (this.addedVertices.hasOwnProperty(index)) {
      newIndex = this.addedVertices[index]
    } else {
      var vertex = this.sourceGeometry.vertices[index]
      // if(this.matrix)
      //     vertex.applyMatrix4(this.matrix);
      this.targetGeometry.vertices.push(vertex)
      newIndex = this.targetGeometry.vertices.length - 1
      this.addedVertices[index] = newIndex
    }
    this.faceIndices.push(newIndex)
  }

  addIntersection(keyA, keyB, distanceA, distanceB) {
    var t = Math.abs(distanceA) / (Math.abs(distanceA) + Math.abs(distanceB))
    this.addIntersectionUv(keyA, keyB, t)
    this.addIntersectionNormal(keyA, keyB, t)

    var indexA = this.sourceFace[keyA]
    var indexB = this.sourceFace[keyB]
    var id = this.intersectionId(indexA, indexB)
    var index

    if (this.addedIntersections.hasOwnProperty(id)) {
      index = this.addedIntersections[id]
    } else {
      var vertexA = this.sourceGeometry.vertices[indexA]
      var vertexB = this.sourceGeometry.vertices[indexB]

      var newVertex = vertexA.clone().lerp(vertexB, t)
      // if(this.matrix){
      //     newVertex.applyMatrix4(this.matrix);
      // }
      this.targetGeometry.vertices.push(newVertex)
      index = this.targetGeometry.vertices.length - 1
      this.addedIntersections[id] = index
    }
    this.faceIndices.push(index)
    this.updateNewEdges(index)
  }

  addUv(key) {
    if (!this.sourceFaceUvs) {
      return
    }
    var index = this.keyIndex(key)
    var uv = this.sourceFaceUvs[index]
    this.faceUvs.push(uv)
  }

  addIntersectionUv(keyA, keyB, t) {
    if (!this.sourceFaceUvs) {
      return
    }
    var indexA = this.keyIndex(keyA)
    var indexB = this.keyIndex(keyB)
    var uvA = this.sourceFaceUvs[indexA]
    var uvB = this.sourceFaceUvs[indexB]
    var uv = uvA.clone().lerp(uvB, t)
    this.faceUvs.push(uv)
  }

  addNormal(key) {
    if (!this.sourceFace.vertexNormals.length) {
      return
    }
    var index = this.keyIndex(key)
    var normal = this.sourceFace.vertexNormals[index]
    // if(this.matrix)
    //     normal.applyMatrix4(this.matrix);
    this.faceNormals.push(normal)
  }

  addIntersectionNormal(keyA, keyB, t) {
    if (!this.sourceFace.vertexNormals.length) {
      return
    }
    var indexA = this.keyIndex(keyA)
    var indexB = this.keyIndex(keyB)
    var normalA = this.sourceFace.vertexNormals[indexA]
    var normalB = this.sourceFace.vertexNormals[indexB]
    var normal = normalA.clone().lerp(normalB, t).normalize()
    // if(this.matrix)
    //     normal.applyMatrix4(this.matrix);
    this.faceNormals.push(normal)
  }

  addFace(indices) {
    if (indices.length === 3) {
      this.addFacePart(indices[0], indices[1], indices[2])
      return
    }

    var pairs = []
    var step = 1
    if (indices.length > 10) {
      step = 100
    }

    for (var i = 0; i < indices.length; i = i + step) {
      for (var j = i + 1; j < indices.length; j++) {
        var diff = Math.abs(i - j)
        if (diff > 1 && diff < indices.length - 1) {
          pairs.push([indices[i], indices[j]])
        }
      }
    }

    if (pairs.length === 0) return
    pairs.sort(
      function (pairA, pairB) {
        var lengthA = this.faceEdgeLength(pairA[0], pairA[1])
        var lengthB = this.faceEdgeLength(pairB[0], pairB[1])
        return lengthA - lengthB
      }.bind(this)
    )

    var a = indices.indexOf(pairs[0][0])
    indices = indices.slice(a).concat(indices.slice(0, a))

    var b = indices.indexOf(pairs[0][1])
    var indicesA = indices.slice(0, b + 1)
    var indicesB = indices.slice(b).concat(indices.slice(0, 1))

    this.addFace(indicesA)
    this.addFace(indicesB)
  }

  addFacePart(a, b, c) {
    var normals = null
    if (this.faceNormals.length) {
      normals = [this.faceNormals[a], this.faceNormals[b], this.faceNormals[c]]
    }
    var face = new THREE.Face3(
      this.faceIndices[a],
      this.faceIndices[b],
      this.faceIndices[c],
      normals
    )
    this.targetGeometry.faces.push(face)
    if (!this.sourceFaceUvs) {
      return
    }
    this.targetGeometry.faceVertexUvs[0].push([this.faceUvs[a], this.faceUvs[b], this.faceUvs[c]])
  }

  faceEdgeLength(a, b) {
    var indexA = this.faceIndices[a]
    var indexB = this.faceIndices[b]
    var vertexA = this.targetGeometry.vertices[indexA]
    var vertexB = this.targetGeometry.vertices[indexB]
    return vertexA.distanceToSquared(vertexB)
  }

  intersectionId(indexA, indexB) {
    return [indexA, indexB].sort().join(',')
  }

  keyIndex(key) {
    return this.FACE_KEYS.indexOf(key)
  }

  updateNewEdges(index) {
    var edgeIndex = this.newEdges.length - 1
    var edge = this.newEdges[edgeIndex]
    if (edge.length < 2) {
      edge.push(index)
    } else {
      this.newEdges.push([index])
    }
  }

  faceNormal(faceIndices) {
    var _this = this
    var vertices = faceIndices.map(function (index) {
      return _this.targetGeometry.vertices[index]
    })
    if (vertices[0] === undefined || vertices.length < 3) return
    var edgeA = vertices[0].clone().sub(vertices[1])
    var edgeB = vertices[0].clone().sub(vertices[2])
    return edgeA.cross(edgeB).normalize()
  }
}
