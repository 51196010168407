import * as THREE from 'three'
import { cloneDeep } from 'lodash'
import ReduxStore from '../../../Redux/Store'
import { Slice } from '../Cut/slice'

const boundingBoxData = [
  {
    axis: 'z',
    direction: '-',
    sides: [
      {
        index: 5,
        vertices: [0, 2],
      },
      {
        index: 3,
        vertices: [2, 3],
      },
      {
        index: 4,
        vertices: [1, 3],
      },
      {
        index: 1,
        vertices: [0, 1],
      },
    ],
  },
  {
    axis: 'x',
    direction: '+',
    sides: [
      {
        index: 5,
        vertices: [0, 1],
      },
      {
        index: 0,
        vertices: [0, 1],
      },
      {
        index: 4,
        vertices: [0, 1],
      },
      {
        index: 2,
        vertices: [0, 1],
      },
    ],
  },
  {
    axis: 'z',
    direction: '+',
    sides: [
      {
        index: 5,
        vertices: [1, 3],
      },
      {
        index: 3,
        vertices: [0, 1],
      },
      {
        index: 4,
        vertices: [0, 2],
      },
      {
        index: 1,
        vertices: [2, 3],
      },
    ],
  },
  {
    axis: 'x',
    direction: '-',
    sides: [
      {
        index: 5,
        vertices: [2, 3],
      },
      {
        index: 0,
        vertices: [2, 3],
      },
      {
        index: 4,
        vertices: [2, 3],
      },
      {
        index: 2,
        vertices: [2, 3],
      },
    ],
  },
  {
    axis: 'y',
    direction: '+',
    sides: [
      {
        index: 0,
        vertices: [1, 3],
      },
      {
        index: 1,
        vertices: [1, 3],
      },
      {
        index: 2,
        vertices: [1, 3],
      },
      {
        index: 3,
        vertices: [1, 3],
      },
    ],
  },
  {
    axis: 'y',
    direction: '-',
    sides: [
      {
        index: 0,
        vertices: [0, 2],
      },
      {
        index: 1,
        vertices: [0, 2],
      },
      {
        index: 2,
        vertices: [0, 2],
      },
      {
        index: 3,
        vertices: [0, 2],
      },
    ],
  },
]

export default class Crop {
  constructor(threeDManager) {
    this.threeDManager = threeDManager
    this.scene = threeDManager.scene
    this.renderer = threeDManager.renderer
    this.renderer.localClippingEnabled = true
    this.camera = threeDManager.camera
    this.canvas = threeDManager.renderer.domElement
    this.orbitCtrl = threeDManager.controls
    this.raycaster = new THREE.Raycaster()
    this.active = false
    this.passed = true
    this.faces = []
    this.totalGroup = new THREE.Group()
    this.scene.add(this.totalGroup)
    for (let i = 0; i < 6; i++) {
      let plane = new THREE.Mesh(
        new THREE.PlaneGeometry(100, 100),
        new THREE.MeshPhongMaterial({
          color: 0x0055ff,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 0.2,
          wireframe: true,
        })
      )
      this.faces.push(plane)
      this.scene.add(this.faces[i])
      this.faces[i].visible = true
    }
    this.defaultObjectSize = 50
    this.addEventListeners()

    this.Slice = new Slice()
  }

  createCornerTemplate(size) {
    const geometry = new THREE.CylinderGeometry(size, size, size * 20, 32)
    const material = new THREE.MeshBasicMaterial({ color: 0x0000ff })
    const mesh1 = new THREE.Mesh(geometry, material)
    const mesh2 = mesh1.clone()
    mesh2.rotation.z = Math.PI / 2
    mesh1.position.y = size * 9
    mesh2.position.x = size * 9
    const object = new THREE.Object3D()
    object.add(mesh1)
    object.add(mesh2)
    return object
  }

  addEventListeners() {
    let mousedown = false
    let drag = 0
    this.canvas.addEventListener('pointerdown', (e) => {
      if (!this.active) return
      mousedown = true
    })

    this.canvas.addEventListener('pointermove', (e) => {
      if (!this.active) return
      if (mousedown) drag++
    })

    this.canvas.addEventListener('pointerup', (e) => {
      if (!this.active) return
      mousedown = false
      if (drag < 5) {
        let intersects = this.getIntersect(e)
        if (intersects.length > 0) {
          if (this.selectedFace && this.selectedFace === intersects[0].object) return
          this.selectedFace = intersects[0].object
          this.initFaces()
          const selectedIdx = this.selectedFace.index
          let sliderValue = -1
          if (selectedIdx === 0 || selectedIdx === 2) {
            sliderValue = (this.planes[selectedIdx].constant * -2) / this.zLen
          } else if (selectedIdx === 1 || selectedIdx === 3) {
            sliderValue = (this.planes[selectedIdx].constant * -2) / this.xLen
          } else {
            sliderValue = (this.planes[selectedIdx].constant * -2) / this.yLen
          }
          ReduxStore.dispatch({ type: 'setCropValue', data: sliderValue })
          this.selectedFace.material.wireframe = false
        }
      }
      drag = 0
    })
  }

  initFaces() {
    this.faces.forEach((face) => {
      face.material.wireframe = true
    })
  }

  init(object, initializeSize = false) {
    this.disposeAll()
    object.updateMatrix()
    object.updateMatrixWorld()
    const box = new THREE.Box3().setFromObject(object)
    this.modelSize = box.getSize(new THREE.Vector3()).length()
    this.corner = this.createCornerTemplate(this.modelSize / 200)

    this.object = object
    this.boxHelper = new THREE.BoxHelper(object, 0xff0000)
    let positions = this.boxHelper.geometry.attributes.position.array
    let vertices = []
    for (let i = 0; i < positions.length / 3; i++) {
      vertices.push(new THREE.Vector3(positions[i * 3], positions[i * 3 + 1], positions[i * 3 + 2]))
    }
    this.boxHelper.xLen = vertices[0].x - vertices[1].x
    this.boxHelper.yLen = vertices[0].y - vertices[3].y
    this.boxHelper.zLen = vertices[0].z - vertices[4].z
    if (initializeSize) {
      this.xLen = vertices[0].x - vertices[1].x
      this.yLen = vertices[0].y - vertices[3].y
      this.zLen = vertices[0].z - vertices[4].z
    }

    const array = [
      [1, 2, 0, 3],
      [1, 2, 5, 6],
      [5, 6, 4, 7],
      [4, 7, 0, 3],
      [6, 2, 7, 3],
      [1, 5, 0, 4],
    ]
    for (let i = 0; i < this.faces.length; i++) {
      for (let j = 0; j < 4; j++) {
        const pos = this.get2DVertice(vertices[array[i][j]], i)
        this.faces[i].geometry.vertices[j].copy(pos)
        if (i < 4) this.faces[i].add(this.createCorner(pos, i, j))
      }
      if (i === 0 || i === 2) {
        this.faces[i].position.z = vertices[array[i][0]].z
      } else if (i === 1 || i === 3) {
        this.faces[i].position.x = vertices[array[i][0]].x
      } else if (i === 4 || i === 5) {
        this.faces[i].position.y = vertices[array[i][0]].y
      }
      this.faces[i].geometry.verticesNeedUpdate = true
      this.faces[i].index = i
    }

    this.planes = [
      new THREE.Plane(new THREE.Vector3(0, 0, -1), this.zLen / 2),
      new THREE.Plane(new THREE.Vector3(-1, 0, 0), -this.xLen / 2),
      new THREE.Plane(new THREE.Vector3(0, 0, -1), -this.zLen / 2),
      new THREE.Plane(new THREE.Vector3(-1, 0, 0), this.xLen / 2),
      new THREE.Plane(new THREE.Vector3(0, -1, 0), -this.yLen / 2),
      new THREE.Plane(new THREE.Vector3(0, -1, 0), this.yLen / 2),
    ]
    this.planes[2].negate()
    this.planes[1].negate()
    this.planes[4].negate()

    const planeGeom = new THREE.PlaneGeometry(400, 400)

    for (let i = 0; i < this.planes.length; i++) {
      const plane = this.planes[i]
      const stencilGroup = this.createPlaneStencilGroup(object.geometry.clone(), plane, i + 1)

      // plane is clipped by the other clipping planes
      const planeMat = new THREE.MeshStandardMaterial({
        color: 0xe91e63,
        metalness: 0.1,
        roughness: 0.75,
        clippingPlanes: this.planes.filter((p) => p !== plane),

        stencilWrite: true,
        stencilRef: 0,
        stencilFunc: THREE.NotEqualStencilFunc,
        stencilFail: THREE.ReplaceStencilOp,
        stencilZFail: THREE.ReplaceStencilOp,
        stencilZPass: THREE.ReplaceStencilOp,
      })
      const po = new THREE.Mesh(planeGeom, planeMat)
      po.onAfterRender = () => {
        this.renderer.clearStencil()
      }

      po.renderOrder = i + 1.1
      this.totalGroup.add(stencilGroup)
    }

    object.material.clippingPlanes = this.planes
    // add the color
    const clippedColorFront = new THREE.Mesh(object.geometry, object.material)
    clippedColorFront.castShadow = true
    clippedColorFront.renderOrder = 6
    this.totalGroup.add(clippedColorFront)
    this.totalGroup.visible = false

    this.deactivateClipping()
  }

  activateClipping() {
    if (this.object) this.object.material.clippingPlanes = this.planes
  }

  deactivateClipping() {
    if (this.object) this.object.material.clippingPlanes = []
  }

  disposeAll() {
    const objectsToDispose = [this.corner, this.boxHelper, this.totalGroup]
    objectsToDispose.forEach((obj) => this.Destroy(obj))
    this.faces.forEach((face) => {
      face.children.forEach((child) => this.Destroy(child))
      face.children = []
    })
  }

  Destroy(mesh) {
    if (!mesh) return
    if (mesh.geometry && mesh.geometry instanceof THREE.Geometry) {
      mesh.geometry.dispose()
    }
    if (mesh.material) mesh.material.dispose()

    if (mesh.children.length > 0) {
      for (var i = 0; i < mesh.children.length; i++) {
        this.Destroy(mesh.children[i])
      }
    }
    this.scene.remove(mesh)
    mesh = undefined
  }

  createCorner(position, faceidx, vertexIdx) {
    const pi = Math.PI
    const arr = [
      [
        [1, 0, 0],
        [0, 0, 0],
        [1, 1, 0],
        [0, 1, 0],
      ],
      [
        [-0.5, 0.5, 0],
        [0, 0.5, 0],
        [0.5, -0.5, 0],
        [0, -0.5, 0],
      ],
      [
        [1, 0, 0],
        [0, 0, 0],
        [1, 1, 0],
        [0, 1, 0],
      ],
      [
        [0, -0.5, -0.5],
        [0, -0.5, 0],
        [-0.5, 0.5, 0],
        [0, 0.5, 0],
      ],
    ]
    const corner = this.corner.clone()
    const rot = arr[faceidx][vertexIdx]
    corner.rotation.set(rot[0] * pi, rot[1] * pi, rot[2] * pi)
    corner.position.copy(position)

    return corner
  }

  get2DVertice(vertice, index) {
    if (index === 0 || index === 2) {
      return new THREE.Vector3(vertice.x, vertice.y, 0)
    } else if (index === 1 || index === 3) {
      return new THREE.Vector3(0, vertice.y, vertice.z)
    } else if (index === 4 || index === 5) {
      return new THREE.Vector3(vertice.x, 0, vertice.z)
    }
  }

  getIntersect(event) {
    const mouse = new THREE.Vector2()
    if (!this.scene) return
    const w = this.canvas.clientWidth
    const h = this.canvas.clientHeight
    const offsetTop = this.getOffset(this.canvas).top
    const offsetLeft = this.getOffset(this.canvas).left

    mouse.x = ((event.clientX - offsetLeft + window.scrollX) / w) * 2 - 1
    mouse.y = -((event.clientY - offsetTop + window.scrollY) / h) * 2 + 1
    this.raycaster.setFromCamera(mouse, this.camera)
    const intersects = this.raycaster.intersectObjects(this.faces, false)
    return intersects
  }

  getOffset(el) {
    var _x = 0
    var _y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft
      _y += el.offsetTop - el.scrollTop
      el = el.offsetParent
    }
    return { top: _y, left: _x }
  }

  activate() {
    this.active = true
    if (!this.object) return
    this.faces.forEach((face) => {
      face.visible = true
    })
    this.totalGroup.visible = true
  }

  deactivate() {
    this.active = false
    this.faces.forEach((face) => {
      face.visible = false
    })
    this.totalGroup.visible = false
    this.deactivateClipping()
  }

  moveFace(value) {
    if (!this.selectedFace || !this.active) return
    this.activateClipping()
    const faceData = boundingBoxData[this.selectedFace.index]
    const planeIdx = this.selectedFace.index
    if (this.passed && this.comeClose(planeIdx)) {
      this.passed = false
      return
    }
    this.passed = true
    const sign = faceData.direction === '-' ? -1 : 1

    switch (faceData.axis) {
      case 'x':
        this.planes[planeIdx].constant = (-1 * value * this.xLen) / 2
        this.faces[planeIdx].position.x = (sign * value * this.xLen) / 2

        faceData.sides.forEach((side) => {
          const diff = (sign * value * this.xLen) / 2
          this.faces[side.index].geometry.vertices[side.vertices[0]].x = diff
          this.faces[side.index].geometry.vertices[side.vertices[1]].x = diff
          this.faces[side.index].geometry.verticesNeedUpdate = true
          if (side.index > 3) return
          this.faces[side.index].children[side.vertices[0]].position.x = diff
          this.faces[side.index].children[side.vertices[1]].position.x = diff
        })
        break
      case 'y':
        this.planes[planeIdx].constant = (-1 * value * this.yLen) / 2
        this.faces[planeIdx].position.y = (sign * value * this.yLen) / 2
        faceData.sides.forEach((side) => {
          const diff = (sign * value * this.yLen) / 2
          this.faces[side.index].geometry.vertices[side.vertices[0]].y = diff
          this.faces[side.index].geometry.vertices[side.vertices[1]].y = diff
          this.faces[side.index].geometry.verticesNeedUpdate = true
          if (side.index > 3) return
          this.faces[side.index].children[side.vertices[0]].position.y = diff
          this.faces[side.index].children[side.vertices[1]].position.y = diff
        })
        break
      case 'z':
        this.planes[planeIdx].constant = (-1 * value * this.zLen) / 2
        this.faces[planeIdx].position.z = (sign * value * this.zLen) / 2
        faceData.sides.forEach((side) => {
          const diff = (sign * value * this.zLen) / 2
          this.faces[side.index].geometry.vertices[side.vertices[0]].z = diff
          this.faces[side.index].geometry.vertices[side.vertices[1]].z = diff
          this.faces[side.index].geometry.verticesNeedUpdate = true
          if (side.index > 3) return
          this.faces[side.index].children[side.vertices[0]].position.z = diff
          this.faces[side.index].children[side.vertices[1]].position.z = diff
        })
        break
      default:
        break
    }
  }

  comeClose(idx) {
    switch (idx) {
      case 0:
      case 2:
        if (this.planes[0].constant + this.planes[2].constant < this.modelSize * 0.2) return true
        break
      case 1:
      case 3:
        if (this.planes[3].constant + this.planes[1].constant < this.modelSize * 0.2) return true
        break
      case 4:
      case 5:
        if (this.planes[5].constant + this.planes[4].constant < this.modelSize * 0.2) return true
        break
      default:
        break
    }
    return false
  }

  apply() {
    if (!this.object) {
      console.warn('failed to crop the model')
      return
    }
    let buffer = this.object.geometry.clone()
    this.planes.forEach((p) => {
      buffer = this.Slice.sliceGeometry(buffer, p, false, this.object.matrix)
    })
    const oldGeometry = cloneDeep(this.object.geometry)
    if (this.object.geometry instanceof THREE.Geometry) this.object.geometry.dispose()
    this.object.geometry = buffer
    this.threeDManager.sendToSculpt(this.object)
    this.init(this.object)
    return { oldGeometry: oldGeometry, newGeometry: buffer }
  }

  createPlaneStencilGroup(geometry, plane, renderOrder) {
    const group = new THREE.Group()
    const baseMat = new THREE.MeshBasicMaterial()
    baseMat.depthWrite = false
    baseMat.depthTest = false
    baseMat.colorWrite = false
    baseMat.stencilWrite = true
    baseMat.stencilFunc = THREE.AlwaysStencilFunc

    // back faces
    const mat0 = baseMat.clone()
    mat0.side = THREE.BackSide
    mat0.clippingPlanes = [plane]
    mat0.stencilFail = THREE.IncrementWrapStencilOp
    mat0.stencilZFail = THREE.IncrementWrapStencilOp
    mat0.stencilZPass = THREE.IncrementWrapStencilOp

    const mesh0 = new THREE.Mesh(geometry, mat0)
    mesh0.renderOrder = renderOrder
    group.add(mesh0)

    // front faces
    const mat1 = baseMat.clone()
    mat1.side = THREE.FrontSide
    mat1.clippingPlanes = [plane]
    mat1.stencilFail = THREE.DecrementWrapStencilOp
    mat1.stencilZFail = THREE.DecrementWrapStencilOp
    mat1.stencilZPass = THREE.DecrementWrapStencilOp

    const mesh1 = new THREE.Mesh(geometry, mat1)
    mesh1.renderOrder = renderOrder

    group.add(mesh1)

    return group
  }
}
