import React, { Component } from 'react'
import ThreeDView from './ThreeDView'
import { main, loadFile, loadObjFiles } from './main/main'
import { connect } from 'react-redux'
import { getFileType } from '../../lib/utils'
import './main.css'
import './kendo.css'

class ThreeDViewController extends Component {
  handleShow = () => this.props.handleShow()

  componentDidMount() {
    main({ ...this.props })
  }

  loadFiles = async (event) => {
    event.stopPropagation()
    event.preventDefault()

    var files = event.dataTransfer ? event.dataTransfer.files : event.target.files
    if (files.length === 3) {
      const file1 = files[0]
      const file2 = files[1]
      const file3 = files[2]
      const fileType1 = getFileType(file1.name)
      const fileType2 = getFileType(file2.name)
      const fileType3 = getFileType(file3.name)
      const obj =
        fileType1 === 'obj'
          ? file1
          : fileType2 === 'obj'
          ? file2
          : fileType3 === 'obj'
          ? file3
          : undefined
      const mtl =
        fileType1 === 'mtl'
          ? file1
          : fileType2 === 'mtl'
          ? file2
          : fileType3 === 'mtl'
          ? file3
          : undefined
      let jpg =
        fileType1 === 'jpg'
          ? file1
          : fileType2 === 'jpg'
          ? file2
          : fileType3 === 'jpg'
          ? file3
          : undefined
      const png =
        fileType1 === 'png'
          ? file1
          : fileType2 === 'png'
          ? file2
          : fileType3 === 'png'
          ? file3
          : undefined
      if (!jpg && png) jpg = png
      if (!obj || !mtl || !jpg) {
        console.log('some files are missing')
        return
      }
      const objFiles = [obj, mtl, jpg]
      let promise = objFiles.map((file, index) => {
        // Define a new file reader
        let reader = new FileReader()

        // Create a new promise
        return new Promise((resolve) => {
          // Resolve the promise after reading file
          reader.onload = () => resolve(reader.result)

          // Read the file as a text
          if (index === 1 || index === 0) reader.readAsText(file)
          else reader.readAsArrayBuffer(file)
        })
      })

      // At this point you'll have an array of results
      let buffers = await Promise.all(promise)
      loadObjFiles(buffers)
    } else if (files.length === 1) {
      const file = files[0]
      const fileType = getFileType(file.name)
      if (fileType === 'stl') {
        try {
          // setLoadState('loading')
          this.props.setLoadState('loading')
          const reader = new FileReader()
          reader.readAsArrayBuffer(file)
          reader.onload = (evt) => {
            loadFile(evt.target.result, fileType)
            this.props.setIsStl(true)
            this.props.setLoadState('loaded')
            this.props.history.push('/')
          }
        } catch (err) {
          this.props.setLoadState('preload')
          console.log(err.message)
        }
      } else if (fileType === 'glb' || fileType === 'gltf') {
        try {
          this.props.setLoadState('loading')
          const reader = new FileReader()
          reader.readAsArrayBuffer(file)
          reader.onload = function (evt) {
            loadFile(evt.target.result, fileType)
            this.props?.setIsStl(false)
            this.props?.setLoadState('loaded')
            this.props?.history.push('/')
          }
        } catch (err) {
          this.props.setLoadState('preload')
          console.log(err.message)
        }
      }
    } else {
      console.warn('Input Error!')
    }
  }

  render() {
    return (
      <ThreeDView
        handleShow={this.handleShow}
        loadState={this.props.loadState}
        loadFiles={this.loadFiles}
        localFileRead={this.props.localFileRead}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    loadState: state.LoadState,
    localFileRead: state.LocalFileRead,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLoadState: (value) => dispatch({ type: 'setLoadState', data: value }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThreeDViewController)
