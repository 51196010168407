import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import app from '../../firebase'
import ThreeDViewController from '../3dView/ThreeDViewController'
import Controller from './Controller'
import Header from './Header'
import './main.css'
import NavBar from './NavBar/Navbar'

function ViewOptionsController(props) {
  const [initControl, setInitControl] = useState(false)
  const [initHeader, setInitHeader] = useState(false)
  const [show, setShow] = useState(false)
  const [isStl, setIsStl] = useState(true)
  const db = app.firestore()
  const { currentUser } = useAuth()
  const history = useHistory()

  const updateInitControl = () => {
    setInitControl(!initControl)
  }

  const updateInitHeader = () => {
    setInitHeader(!initHeader)
  }

  const handleShow = () => {
    setShow(!show)
  }

  useEffect(() => {
    if (window.innerWidth > 768) setShow(true)
    db.collection('customers')
      .doc(currentUser.uid)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        if (snapshot.docs.length === 0) history.push('./signupincomplete')
      })
  }, [db, currentUser.uid, history])

  return (
    <div style={{ height: '100vh', background: '#fafafc' }}>
      <Row>
        <Col>
          <NavBar />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <Header setInitControl={updateInitControl} initHeader={initHeader} />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 7, offset: 1 }} xl={{ span: 8, offset: 1 }}>
          <ThreeDViewController handleShow={handleShow} setIsStl={setIsStl} history={history} />
        </Col>
        <Col md={{ span: 3 }} xl={{ span: 2 }}>
          {show && (
            <Controller
              handleShow={handleShow}
              initControl={initControl}
              setInitHeader={updateInitHeader}
              isStl={isStl}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default ViewOptionsController
