import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import './modal.css'

const DownloadModal = (props) => {
  const { isModalOpen, onDownload, handleClose } = props
  return (
    <div className="download-modal">
      <Modal show={isModalOpen} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Export Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Using the Pay per use option, we will bill you at the end of the month for every model
            you downloaded.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="download-btn d-flex flex-column">
            <Button variant="dark" onClick={onDownload}>
              Download Model
            </Button>
            <Button variant="light" onClick={handleClose} className="mt-2">
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DownloadModal
