import React, { useRef, useState, useEffect } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import queryString from 'querystring'
import { useHistory } from 'react-router-dom'
import { auth } from '../../firebase'
import logo from 'assets/img/logo.png'
import r3dent from '../../assets/img/r3dent_big.svg'
import { Typography } from '@mui/material'

export default function ForgotPassword(props) {
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser } = useAuth()
  const [verifying, setVerifying] = useState(true)
  const [verified, setVerified] = useState(false)
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [changing, setChanging] = useState(false)
  const [email, setEmail] = useState('')
  const [oobCode, setOobCode] = useState('')
  const history = useHistory()

  setMessage('')
  setEmail('@')
  console.log(currentUser, verifying, verified)

  useEffect((props) => {
    let stringParams = queryString.parse(props.location.search)

    if (stringParams) {
      let oobCode = stringParams.oobCode
      if (oobCode) {
        verifyPasswordResetLink(oobCode)
      } else {
        setError('Unable to find code')
        setVerified(false)
        setVerifying(false)
      }
    }
  }, [])

  const verifyPasswordResetLink = (_oobCode) => {
    auth
      .verifyPasswordResetCode(_oobCode)
      .then((result) => {
        // logging.info(result);
        setOobCode(_oobCode)
        setVerified(true)
        setVerifying(false)
      })
      .catch((error) => {
        // logging.error(error);
        setVerified(false)
        setVerifying(false)
      })
  }

  const passwordResetRequest = () => {
    let password = passwordRef.current.value
    let passwordConfirm = passwordConfirmRef.current.value

    if (password !== passwordConfirm) {
      setError('Make sure your passwords are matching')
      return
    }
    if (error !== '') setError('')
    setChanging(true)
    auth
      .confirmPasswordReset(oobCode, password)
      .then(() => {
        history.push('/resetSuccess')
      })
      .catch((error) => {
        // logging.error(error);
        console.error(error.message)
        setError(error.message)
        setChanging(false)
      })
  }

  return (
    <section className="forgotPassword">
      <div
        style={{
          textAlign: 'center',
          height: '13vh',
          fontSize: '40px',
          fontWeight: '600',
          display: 'flex',
          justifyContent: 'center',
          color: '#fff',
        }}
      >
        <img src={r3dent} style={{ width: '200px' }} alt="logo" />
      </div>
      <Card className="authCard">
        <Card.Body>
          <Typography variant="h4" align="left" className="mb-4 cardheader">
            Reset Password
          </Typography>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <p>Choose a new password for: {email}</p>
          <Form>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label className="mt-4">Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="password"
                ref={passwordConfirmRef}
                required
              />
            </Form.Group>
            <Button
              disabled={changing}
              variant="dark"
              className="w-100 mt-4"
              type="submit"
              onClick={passwordResetRequest}
            >
              Change Password
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="made-by">
        made by
        <br />
        <img src={logo} alt="logo img"></img>
      </div>
    </section>
  )
}
