import * as THREE from '../../../../node_modules/three/build/three.module.js'
import { GeometryBuilder } from './GeometryBuilder.js'

export class Slice {
  constructor() {
    this.FRONT = 'front'
    this.BACK = 'back'
    this.ON = 'on'
    this.FACE_KEYS = ['a', 'b', 'c']
    this.matrix = null
  }

  sliceGeometry(mesh, plane, closeHoles, matrix = null) {
    this.matrix = matrix

    var geometry
    if (mesh.geometry instanceof THREE.BufferGeometry) {
      geometry = new THREE.Geometry().fromBufferGeometry(mesh.geometry)
    } else if (mesh instanceof THREE.Geometry) {
      geometry = mesh
    } else if (mesh instanceof THREE.BufferGeometry) {
      geometry = new THREE.Geometry().fromBufferGeometry(mesh)
    } else {
      geometry = mesh.geometry
      this.matrix = mesh.matrix.clone()
    }
    var _this = this
    var sliced = new THREE.Geometry()
    var builder = new GeometryBuilder(geometry, sliced, plane, this.matrix)
    var distances = []
    var positions = []
    // if(this.matrix)
    //     geometry.applyMatrix4(this.matrix);
    // if(this.matrix)
    //     geometry.vertices.map(v => v.applyMatrix4(this.matrix))
    geometry.vertices.forEach(function (vertex) {
      // distance from point to plane
      // if(_this.matrix)
      //     vertex.applyMatrix4(_this.matrix);
      var distance = _this.findDistance(vertex, plane)

      var position = _this.distanceAsPosition(distance, 1)
      distances.push(distance)
      positions.push(position)
    })

    geometry.faces.forEach(function (face, faceIndex) {
      var facePositions = _this.FACE_KEYS.map(function (key) {
        return positions[face[key]]
      })

      if (facePositions.indexOf(_this.FRONT) === -1 && facePositions.indexOf(_this.BACK) !== -1) {
        return
      }

      builder.startFace(faceIndex)

      var lastKey = _this.FACE_KEYS[_this.FACE_KEYS.length - 1]
      var lastIndex = face[lastKey]
      var lastDistance = distances[lastIndex]
      var lastPosition = positions[lastIndex]

      _this.FACE_KEYS.map(function (key) {
        var index = face[key]
        var distance = distances[index]
        var position = positions[index]

        if (position === _this.FRONT) {
          if (lastPosition === _this.BACK) {
            builder.addIntersection(lastKey, key, lastDistance, distance)
            builder.addVertex(key)
          } else {
            builder.addVertex(key)
          }
        }

        if (position === _this.ON) {
          builder.addVertex(key)
        }

        if (position === _this.BACK && lastPosition === _this.FRONT) {
          builder.addIntersection(lastKey, key, lastDistance, distance)
        }

        lastKey = key
        lastIndex = index
        lastPosition = position
        lastDistance = distance
        return ''
      })

      builder.endFace()
    })

    if (closeHoles) {
      builder.closeHoles()
    }
    return sliced
  }

  distanceAsPosition(distance, centerDistance) {
    if (distance * centerDistance < 0) {
      return this.BACK
    }
    if (distance * centerDistance > 0) {
      return this.FRONT
    }
    return this.ON
  }

  findDistance(vertex, plane) {
    return plane.distanceToPoint(vertex)
  }
}
