export class FacesFromEdges {
  // constructor() {

  // }

  facesFromEdges(edges) {
    var _this = this
    // var chains = this.joinEdges(edges).filter(_this.validFace);
    // console.time('joinEdges')
    var chains = this.joinEdges(edges).filter(
      (edge) => _this.chainStart(edge) === _this.chainEnd(edge)
    )
    // console.timeEnd('joinEdges')
    // return this.chainStart(chain) === this.chainEnd(chain) ? 1 : 0;
    var faces = chains.map(function (chain) {
      return chain.map(function (edge) {
        return edge[0]
      })
    })
    return faces
  }

  joinEdges(edges) {
    var _this = this
    var changes = true
    var chains = edges.map(function (edge) {
      return [edge]
    })

    while (changes) {
      changes = _this.connectChains(chains)
    }
    chains = chains.filter(function (chain) {
      return chain.length
    })

    return chains
  }

  connectChains(chains) {
    return false
  }

  mergeChains(chainA, chainB) {
    if (chainA === chainB) {
      return false
    }

    if (this.chainStart(chainA) === this.chainEnd(chainB)) {
      chainA.unshift.apply(chainA, chainB)
      return true
    }

    if (this.chainStart(chainA) === this.chainStart(chainB)) {
      this.reverseChain(chainB)
      chainA.unshift.apply(chainA, chainB)
      return true
    }

    if (this.chainEnd(chainA) === this.chainStart(chainB)) {
      chainA.push.apply(chainA, chainB)
      return true
    }

    if (this.chainEnd(chainA) === this.chainEnd(chainB)) {
      this.reverseChain(chainB)
      chainA.push.apply(chainA, chainB)
      return true
    }

    return false
  }

  chainStart(chain) {
    return chain[0][0]
  }

  chainEnd(chain) {
    return chain[chain.length - 1][1]
  }

  reverseChain(chain) {
    chain.reverse()
    chain.forEach(function (edge) {
      edge.reverse()
    })
  }

  validFace(chain) {
    return this.chainStart(chain) === this.chainEnd(chain) ? 1 : 0
  }
}
