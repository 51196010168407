import React, { useState, useEffect, useMemo } from 'react'
import app from 'firebase'
import 'firebase/compat/firestore'
import { useAuth } from '../../contexts/AuthContext'
import SubscriptionCard from './SubscriptionCard'
import MySubscriptionCard from './MySubscriptionCard'
import './main.css'
import { Row, Col } from 'react-bootstrap'
import NavBar from 'components/UI/NavBar/Navbar'

import { getRemoteConfig, getValue } from 'firebase/remote-config'

export default function Subscription() {
  const { currentUser } = useAuth()

  const uid = currentUser && currentUser.uid

  const [interval, setInter] = useState('month')
  const [subscriptions, setSubscriptions] = useState([])
  const [subscribed, setSubscribed] = useState(false)
  const [prices, setPrices] = useState()
  const [plan, setPlan] = useState()
  const db = app.firestore()

  const remoteConfig = getRemoteConfig()
  const planRecommendedId = getValue(remoteConfig, 'recommended_subscription_card_id')

  useEffect(() => {
    if (db !== undefined && uid) {
      db.collection('customers')
        .doc(uid)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .onSnapshot(async (snapshot) => {
          if (snapshot.empty) {
            setSubscribed(false)

            db.collection('products')
              .where('active', '==', true)
              .where('metadata.type', '==', 'r3dcloud')
              .get()
              .then(function (querySnapshot) {
                setSubscriptions([])
                querySnapshot.forEach(async function (doc, docIndex) {
                  const priceSnap = await doc.ref
                    .collection('prices')
                    .where('active', '==', true)
                    .orderBy('unit_amount')
                    .get()
                  const product = doc.data()
                  if (priceSnap) {
                    setSubscriptions((existingModel) => [
                      ...priceSnap.docs.map((doc, docIndex) => {
                        const priceId = doc.id
                        const priceData = doc.data()
                        const { currency, unit_amount, name, description, interval, tiers } =
                          priceData
                        const new_item = {
                          currency: currency,
                          unit_amount: unit_amount
                            ? unit_amount / 100
                            : tiers[0]?.unit_amount / 100,
                          name: product.name,
                          description,
                          price_id: priceId,
                          interval: unit_amount ? 'month' : 'donwload',
                          id: existingModel.length,
                        }
                        return new_item
                      }),
                      ...existingModel,
                    ])
                  }
                })
              })
          } else {
            setSubscribed(true)
            const _subscription = snapshot.docs[0].data()
            const priceData = (await _subscription.price.get()).data()
            setPrices(priceData)
          }
        })
    }
  }, [db, interval, uid])

  const choosePlan = async (price_id, isRecommended) => {
    // setPlan(price_id)

    const docRef = await db
      .collection('customers')
      .doc(uid)
      .collection('checkout_sessions')
      .add({
        ...(isRecommended
          ? { price: price_id }
          : {
              line_items: [
                {
                  price: price_id, // metered billing price
                },
              ],
            }),
        allow_promotion_codes: true,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      })

    if (docRef) {
      docRef.onSnapshot((snap) => {
        const { sessionId } = snap.data()
        if (sessionId) {
          const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
          stripe.redirectToCheckout({ sessionId })
        }
      })
    }
  }

  return (
    <div className="subscription-container">
      <Row>
        <Col>
          <NavBar hideBilling hideFiles logout />
        </Col>
      </Row>
      <section className="subscription">
        <h2>{subscribed ? 'My subscription' : 'Simple, transparent pricing'}</h2>
        <p>Choose what model works for you</p>

        {subscribed ? (
          <div
            className="d-flex flex-row justify-content-center align-items-center mt-5"
            style={{ background: 'white', borderRadius: '22px' }}
          >
            <MySubscriptionCard price={prices} />
          </div>
        ) : (
          <>
            <div
              className="d-flex flex-column flex-sm-row justify-content-center align-items-start mt-5"
              style={{ borderRadius: '22px' }}
            >
              {subscriptions.map((subscription) => (
                <SubscriptionCard
                  key={subscription.id}
                  subscription={subscription}
                  choosePlan={choosePlan}
                  isRecommendedId={planRecommendedId._value || null}
                />
              ))}
            </div>
          </>
        )}
      </section>
    </div>
  )
}
