import {
  Button as ButtonMui,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import DownloadModal from 'components/modals/DownloadModal'
import Slider from 'components/Slider'
import { useAuth } from 'contexts/AuthContext'
import app from 'firebase'
import 'firebase/compat/firestore'
import { useEffect, useState } from 'react'
import { Button, Alert } from 'react-bootstrap'
import { HiOutlineChevronDoubleLeft } from 'react-icons/all'
import { connect, useSelector } from 'react-redux'
import uuid from 'react-uuid'
import cropImg from '../../assets/img/crop.svg'
import cropWhiteImg from '../../assets/img/crop_white.svg'
import fillImg from '../../assets/img/fill.svg'
import fillWhiteImg from '../../assets/img/fill_white.svg'
import hollowImg from '../../assets/img/hollow.svg'
import hollowWhiteImg from '../../assets/img/hollow_white.svg'
import scaleImg from '../../assets/img/scale.svg'
import scaleWhiteImg from '../../assets/img/scale_white.svg'
import {
  changeLength,
  changeRadius,
  crop,
  cropFinish,
  cropMode,
  exportSTL,
  fillAddMode,
  handleCropValueChange,
  hollow,
  hollowMode,
  initControl,
  remesh,
  removeCutGadgets,
  scale,
  setBrush,
  setControlMode,
  setTransformMode,
  viewMode,
} from '../3dView/main/main'
import { controlMode } from '../3dView/main/threeDManager'
import './main.css'

const Controller = (props) => {
  const loadState = useSelector((state) => state.LoadState)

  const { currentUser } = useAuth()

  const [mode, setMode] = useState('scale')
  const [fillMode, setFillMode] = useState('add')
  const [transformCtrl, setTransformCtrl] = useState('translate')
  const [realScale, setRealScale] = useState('')
  const [error, setError] = useState(null)

  const [promptDownloadModal, setPromptDownloadModal] = useState(false)

  const fileLoaded = Boolean(props.loadState === 'loaded')

  const db = app.firestore()

  useEffect(() => {
    removeCutGadgets()
    setMode('none')
  }, [props.initControl])

  const setScale = (e) => {
    removeCutGadgets()
    if (mode !== 'scale') {
      initControl(true)
      document.getElementById('measureBtn').click()
      setMode('scale')
      document.getElementById('viewport').style.zIndex = 2

      const distanceDiv = document.querySelector('#labelDiv')
      const scaleDiv = document.querySelector('#realValue')
      if (scaleDiv && distanceDiv) {
        scaleDiv.value = distanceDiv.innerText
      }
    } else {
      setMode('none')
      viewMode()
      props.setInitHeader()
    }
  }

  const setCrop = () => {
    removeCutGadgets()
    if (mode !== 'crop') {
      initControl(true)
      setMode('crop')
      document.getElementById('viewport').style.zIndex = 2
      cropMode()
    } else {
      setMode('none')
      viewMode()
      props.setInitHeader()
    }
  }

  const setMoveHollow = () => {
    setTransformCtrl('translate')
    setTransformMode('translate')
  }

  const setRotateHollow = () => {
    setTransformCtrl('rotate')
    setTransformMode('rotate')
  }

  const setFill = (e) => {
    viewMode()
    if (mode !== 'fill') {
      setMode('fill')
      setControlMode(controlMode.FILL)
      document.getElementById('viewport').style.zIndex = 4
    } else {
      setMode('none')
      document.getElementById('viewport').style.zIndex = 2
      props.setInitHeader()
    }
  }

  const setAdd = (e) => {
    setFillMode('add')
    fillAddMode(true)
  }

  const setSubtract = (e) => {
    setFillMode('subtract')
    fillAddMode(false)
  }

  const setHollow = (e) => {
    if (mode !== 'hollow') {
      setMode('hollow')
      hollowMode()
      document.getElementById('viewport').style.zIndex = 2
    } else {
      setMode('none')
      viewMode()
      props.setInitHeader()
    }
  }

  const radiusChange = (e) => {
    changeRadius(e.target.value)
  }

  const lengthChange = (e) => {
    changeLength(e.target.value)
  }

  const incrementModelsDownloadedCounter = async () => {
    const functionRef = app.functions('us-central1').httpsCallable('reportUsage')
    try {
      const { data } = await functionRef({
        idempotencyID: uuid(),
      })
      if (data) {
        return true
      }
    } catch (error) {
      console.log('Error trying to increase download counter : ', error)
      setError('Error downloading model. Please try again.')
      return false
    }
  }

  const handleExport = async (incrementDownloadsCounter) => {
    console.log('We are here')
    if (incrementDownloadsCounter) {
      const counterIncrementedSuccessfully = await incrementModelsDownloadedCounter()
      if (counterIncrementedSuccessfully) {
        exportSTL()
      }
    } else {
      exportSTL()
    }
  }

  const handleTogglePromptExport = () => {
    setPromptDownloadModal((modalState) => !modalState)
  }

  const handleExportClick = (incrementDownloadsCounter = true) => {
    if (loadState === 'preload' || loadState === undefined) {
      alert('There is no file for export!')
    } else {
      handleExport(incrementDownloadsCounter)
    }
    promptDownloadModal && handleTogglePromptExport()
  }

  const handleDownloadModel = () => {
    db.collection('customers')
      .doc(currentUser.uid)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        const _subscription = snapshot.docs[0].data()
        const subscriptionPrice = await _subscription.price.get()
        console.log(_subscription.price)
        console.log(subscriptionPrice.data())
        const { interval, trial_period_days } = subscriptionPrice.data()

        if (interval === 'month' && !trial_period_days) {
          // dislpay download confirmation prompt only for monthly subscription
          handleTogglePromptExport()
        } else {
          handleExportClick(false)
        }
      })
  }

  const handleSliderChange = (e, newValue) => {
    handleCropValueChange(newValue)
    props.setCropValue(newValue)
  }

  const handleRemoveError = () => {
    if (error) {
      setError(null)
    }
  }

  return (
    <>
      <div className="controller" onClick={error && handleRemoveError}>
        <p className="controller-text">
          Tools <HiOutlineChevronDoubleLeft onClick={props.handleShow} className="chevron-icon" />
        </p>

        <Button
          variant=""
          className={`controlBtn ${mode === 'scale' ? 'activeBtn ' : 'inactiveBtn'}`}
          onClick={setScale}
          disabled={!fileLoaded}
        >
          {mode === 'scale' ? (
            <img src={scaleWhiteImg} alt="scale" />
          ) : (
            <img src={scaleImg} alt="scale" />
          )}
          Scale
        </Button>
        {mode === 'scale' && (
          <div style={{ width: '130px', textAlign: 'left' }}>
            <p style={{ margin: '10px 0px' }}>Real Length</p>

            <label>
              <input
                type="number"
                className={'rc-input-number-input inactiveBtn mb-2'}
                id="realValue"
                min="1"
                value={realScale}
                onChange={(e) => (e.target.value < 80 ? setRealScale(e.target.value) : null)}
              />
              <span className="unit">mm</span>
            </label>
            <ButtonMui
              variant="contained"
              id="scaleApplyBtn"
              className="applyBtn"
              color="secondaryBlack"
              onClick={() => scale(realScale)}
            >
              Apply
            </ButtonMui>
          </div>
        )}
        <Button
          variant=""
          className={`controlBtn ${mode === 'crop' ? 'activeBtn ' : 'inactiveBtn'}`}
          onClick={setCrop}
          disabled={!fileLoaded}
        >
          {mode === 'crop' ? (
            <img src={cropWhiteImg} alt="scale" />
          ) : (
            <img src={cropImg} alt="scale" />
          )}
          Crop
        </Button>
        {mode === 'crop' && (
          <div>
            <Slider
              value={props.cropValue}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              min={-1}
              max={0.6}
              step={0.01}
            />
            <ButtonMui
              color="secondaryBlack"
              variant="contained"
              id="cropApplyBtn"
              className="applyBtn mb-2"
              onClick={crop}
            >
              Apply
            </ButtonMui>
            <ButtonMui
              color="secondaryBlack"
              variant="contained"
              id="cropFinishBtn"
              className="applyBtn"
              onClick={cropFinish}
            >
              Finish
            </ButtonMui>
          </div>
        )}

        <Button
          variant=""
          className={`controlBtn ${mode === 'fill' ? 'activeBtn ' : 'inactiveBtn'}`}
          onClick={setFill}
          disabled={!fileLoaded}
        >
          {mode === 'fill' ? (
            <img src={fillWhiteImg} alt="fill" />
          ) : (
            <img src={fillImg} alt="fill" />
          )}
          Sculpt
        </Button>
        {mode === 'fill' && (
          <div style={{ width: '130px', textAlign: 'left' }}>
            <Typography variant="body2" color="childLabel.text" className="my-2">
              Mode
            </Typography>
            <Button
              className={`subBtn ${fillMode === 'add' ? 'btn-secondary' : 'btn-light'}`}
              variant=""
              onClick={setAdd}
            >
              Add
            </Button>
            <Button
              className={'subBtn'}
              variant={fillMode === 'subtract' ? 'secondary' : 'light'}
              onClick={setSubtract}
            >
              Subtract
            </Button>
            <FormControl component="fieldset">
              <Typography variant="body2" color="childLabel.text" className="my-2">
                Brush thickness
              </Typography>
              <RadioGroup row aria-label="position" name="position" defaultValue="Medium">
                <FormControlLabel
                  value="small"
                  control={<Radio color="secondaryBlack" />}
                  label="Small"
                  labelPlacement="bottom"
                  onClick={() => setBrush(1)}
                />

                <FormControlLabel
                  value="Medium"
                  control={<Radio color="secondaryBlack" />}
                  label="Medium"
                  labelPlacement="bottom"
                  onClick={() => setBrush(2)}
                />

                <FormControlLabel
                  value="Large"
                  control={<Radio color="secondaryBlack" />}
                  label="Large"
                  labelPlacement="bottom"
                  onClick={() => setBrush(3)}
                />
              </RadioGroup>
            </FormControl>
            <ButtonMui
              color="secondaryBlack"
              variant="contained"
              id="cropApplyBtn"
              className="applyBtn my-2"
              onClick={remesh}
            >
              Save
            </ButtonMui>
          </div>
        )}

        <Button
          variant=""
          className={`controlBtn ${mode === 'hollow' ? 'activeBtn ' : 'inactiveBtn'}`}
          onClick={setHollow}
          disabled={!fileLoaded}
        >
          {mode === 'hollow' ? (
            <img src={hollowWhiteImg} alt="hollow" />
          ) : (
            <img src={hollowImg} alt="hollow" />
          )}
          Hollow
        </Button>
        {mode === 'hollow' && (
          <div style={{ width: '130px', textAlign: 'left' }}>
            <p style={{ margin: '10px 0px' }}>Transform</p>
            <Button
              className={'subBtn shadow-none'}
              variant={transformCtrl === 'rotate' ? 'secondary' : 'light'}
              onClick={setRotateHollow}
            >
              Rotate
            </Button>
            <Button
              className={'subBtn shadow-none'}
              variant={transformCtrl === 'translate' ? 'secondary' : 'light'}
              onClick={setMoveHollow}
            >
              Move
            </Button>

            <p style={{ textAlign: 'left', margin: '3px' }}>Hollow Radius</p>
            <label>
              <input
                type="number"
                className={'rc-input-number-input inactiveBtn mb-2'}
                defaultValue={5}
                min="1"
                onChange={radiusChange}
              />
              <span className="unit"> mm</span>
            </label>
            <p style={{ textAlign: 'left', margin: '3px' }}>Hollow Length</p>
            <label>
              <input
                type="number"
                className={'rc-input-number-input  inactiveBtn mb-2'}
                defaultValue={20}
                min="1"
                onChange={lengthChange}
              />
              <span className="unit"> mm</span>
            </label>
            <ButtonMui
              color="secondaryBlack"
              variant="contained"
              id="cropApplyBtn"
              className="applyBtn my-2"
              onClick={hollow}
            >
              Apply
            </ButtonMui>
          </div>
        )}

        <ButtonMui
          color="dark"
          variant="contained"
          onClick={handleDownloadModel}
          className="downloadBtn"
          disabled={!fileLoaded}
        >
          Download
        </ButtonMui>
        {promptDownloadModal && (
          <DownloadModal
            isModalOpen={promptDownloadModal}
            onDownload={handleExportClick}
            handleClose={handleTogglePromptExport}
          />
        )}
      </div>
      <div className="controller-error">{error && <Alert variant="danger">{error}</Alert>}</div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    loadState: state.LoadState,
    cropValue: state.CropValue,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setCropValue: (value) => dispatch({ type: 'setCropValue', data: value }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller)
