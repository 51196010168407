export const getFileType = (name) => {
  var lower = name.toLowerCase()
  if (lower.endsWith('.glb')) return 'glb'
  if (lower.endsWith('.gltf')) return 'gltf'
  if (lower.endsWith('.sgl')) return 'sgl'
  if (lower.endsWith('.stl')) return 'stl'
  if (lower.endsWith('.ply')) return 'ply'
  if (lower.endsWith('.obj')) return 'obj'
  if (lower.endsWith('.mtl')) return 'mtl'
  if (lower.endsWith('.jpg')) return 'jpg'
  if (lower.endsWith('.png')) return 'png'
  return
}
