import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import logo from 'assets/img/logo.png'
import r3dCloudWhite from 'assets/img/r3dent_big.svg'
import 'firebase/compat/firestore'
import { Typography } from '@mui/material'
import { useAuth } from 'contexts/AuthContext'

export default function SignupIncomplete() {
  const history = useHistory()

  const { logout: logoutUser } = useAuth()

  const completeSignup = () => {
    history.push(`/subscribe`)
  }

  const handleLogout = () => {
    logoutUser().then(() => {
      history.push('/login')
    })
  }

  return (
    <section className="login shadow-none">
      <div
        style={{
          textAlign: 'center',
          height: '13vh',
          fontSize: '40px',
          fontWeight: '600',
          display: 'flex',
          justifyContent: 'center',
          color: '#fff',
          marginBottom: '50px',
        }}
      >
        <img src={r3dCloudWhite} style={{ width: '285px' }} alt="logo" />
      </div>
      <Card className="authCard" style={{ maxWidth: 428 }}>
        <Card.Body>
          <Typography variant="h4" align="left" className="mb-4 cardheader">
            Signup Incomplete
          </Typography>
          <div style={{ color: '#2D3338', fontSize: 16, margin: '20px 0px', fontWeight: 400 }}>
            We noticed you did not sign up for a subscription yet. Please click the button below to
            sign up for our subscription in order to use the software.
          </div>
          <Button variant="dark" className="w-100 rounded complete-signup" onClick={completeSignup}>
            Complete Signup
          </Button>
          <Button variant="light" className="w-100 rounded mt-2" onClick={handleLogout}>
            Logout
          </Button>
        </Card.Body>
      </Card>
      <div className="made-by">
        made by
        <br />
        <img src={logo} alt="logo img"></img>
      </div>
    </section>
  )
}
