import React, { useRef, useState } from 'react'
import { Form, Card, Alert } from 'react-bootstrap'
import { Button, Typography } from '@mui/material'
import { useAuth } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import r3dent from '../../assets/img/r3dent_big.svg'
import './main.css'

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError('')
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      history.push('/')
    } catch {
      setError('Failed to log in')
    }

    setLoading(false)
  }

  return (
    <section className="login">
      <div
        style={{
          textAlign: 'center',
          height: '13vh',
          fontSize: '40px',
          fontWeight: '600',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src={r3dent} style={{ width: '200px' }} alt="logo" />
      </div>
      <Card className="authCard">
        <Card.Body>
          <Typography variant="h4" align="left" className="mb-4 cardheader">
            Welcome Back
          </Typography>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="email@email.com" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label className="mt-4">Password</Form.Label>
              <Form.Control type="password" placeholder="password" ref={passwordRef} required />
            </Form.Group>
            <div className="w-100 ">
              <div className="k-text-right">
                <Link className="nodecoration" to="/forgot-password">
                  Forgot Password?
                </Link>
              </div>
            </div>
            <Button
              disabled={loading}
              color="dark"
              variant="contained"
              className="w-100 mt-3 !text-9xl login-button"
              type="submit"
            >
              Login
            </Button>
            <div className="w-100 link-login" style={{ textDecoration: 'none' }}>
              Not a member?&nbsp;
              <Link className="nodecoration" to="/signup">
                Sign up
              </Link>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <div className="made-by">
        made by
        <br />
        <img src={logo} alt="logo img"></img>
      </div>
    </section>
  )
}
