import React from 'react'
import { Form, Button, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import logo from 'assets/img/logo.png'
import r3dent from '../../assets/img/r3dent_big.svg'
import { Typography } from '@mui/material'

export default function ForgotPassword(props) {
  const history = useHistory()
  const returnToLogin = () => {
    history.push('/login')
  }

  return (
    <section className="resetSuccessful">
      <div
        style={{
          textAlign: 'center',
          height: '13vh',
          fontSize: '40px',
          fontWeight: '600',
          display: 'flex',
          justifyContent: 'center',
          color: '#fff',
        }}
      >
        <img src={r3dent} style={{ width: '200px' }} alt="logo" />
      </div>
      <Card className="authCard">
        <Card.Body>
          <Typography variant="h4" align="left" className="mb-4 cardheader">
            Reset Password Successful
          </Typography>
          <p style={{ margin: '20px 0px' }}>You can now login with your new password</p>
          <Form>
            <Button
              variant="dark"
              className="w-100 reset-successful-login-button"
              type="submit"
              onClick={returnToLogin}
            >
              Return to Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="made-by">
        made by
        <br />
        <img src={logo} alt="logo img"></img>
      </div>
    </section>
  )
}
