import React, { useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'

const renderFeatureCheckboxes = (features) => {
  return features.map((featureItem) => (
    <Form.Check type="checkbox" label={featureItem} checked disabled key={featureItem} />
  ))
}

export default function SubscriptionCard({ isRecommendedId, subscription, choosePlan }) {
  const [loading, setLoading] = useState(false)

  const { unit_amount, interval, name } = subscription

  const handlePlan = () => {
    setLoading(true)
    choosePlan(subscription.price_id, isRecommended)
  }

  const isRecommended = subscription.id == 0

  const displayAmount = unit_amount % 1 === 0 ? unit_amount : unit_amount.toFixed(2)

  return (
    <div className={isRecommended ? 'subscription-card active' : 'subscription-card'}>
      <h2>
        €{displayAmount}&nbsp;<span>/{interval}</span>
      </h2>
      <h5 className="text-capitalize font-extrabold">{name}</h5>
      <p>
        {isRecommended
          ? 'Get unlimited exports and 3D print as many files as needed.'
          : 'Pay only when exporting the file you like to 3D print.'}
      </p>
      {!isRecommended
        ? renderFeatureCheckboxes(['Pay per export', 'Upload Scans with App', 'Full functionality'])
        : renderFeatureCheckboxes([
            'Unlimited Exports',
            'Upload Scans with App',
            'Full functionality',
            'Premium Support',
            'Free Trial for 7 days',
          ])}
      <Button className="w-100 btn-sm cursor-pointer" variant="outline-dark" onClick={handlePlan}>
        {loading ? (
          <Spinner animation="border" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : isRecommended ? (
          'Start Free Trial'
        ) : (
          'Start Now'
        )}
      </Button>
    </div>
  )
}
