import React from 'react'
import { Button } from 'react-bootstrap'
import app from '../../firebase'
require('firebase/compat/functions')

export default function MySubscriptionCard({ price }) {
  const accessPortal = async () => {
    // https://europe-west3-digital-dentistry-3784e.cloudfunctions.net/ext-firestore-stripe-subscriptions-createPortalLink
    const functionRef = app
      .functions('europe-west3')
      .httpsCallable('ext-firestore-stripe-payments-createPortalLink')
    const { data } = await functionRef({ returnUrl: window.location.origin })
    window.location.assign(data.url)
  }

  return (
    <div className="subscription-card active">
      <p>
        You are paying €{price !== undefined && price.unit_amount / 100} per{' '}
        {price !== undefined && price.interval}
      </p>
      <h5>View invoices, update subscription & payment methods</h5>
      <Button
        id="billing-portal-button"
        className="w-100 btn-sm"
        variant="outline-dark"
        onClick={accessPortal}
      >
        Access customer portal
      </Button>
    </div>
  )
}
