import { useAuth } from 'contexts/AuthContext'
import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import logo from '../../../assets/img/logo.png'
import app from '../../../firebase'
import ReduxStore from '../../../Redux/Store'
import { clearScene } from '../../3dView/main/main'
import './main.css'

const NavBar = (props) => {
  const { hideFiles, hideBilling, hideLogout } = props

  const { logout: logoutUser, currentUser } = useAuth()

  const handleLogout = async () => {
    try {
      const loggedOut = await logoutUser()
      if (loggedOut) {
        history.push('/login')
      }
    } catch {
      console.log('Failed to log out')
    }
  }

  const history = useHistory()

  const handleRedirection = async () => {
    ReduxStore.dispatch({ type: 'setLoadState', data: 'loading' })
    const functionRef = app
      .functions('europe-west3')
      .httpsCallable('ext-firestore-stripe-payments-createPortalLink')
    const { data } = await functionRef({ returnUrl: window.location.origin })
    window.location.assign(data.url)
    ReduxStore.dispatch({ type: 'setLoadState', data: 'loaded' })
  }

  const displayAvailabledModels = () => {
    if (props.loadState === 'loaded') {
      if (window.confirm('Are you sure you want to clear the scene?')) {
        clearScene()
        ReduxStore.dispatch({ type: 'setLocalFileRead', data: false })
      }
    } else {
      ReduxStore.dispatch({ type: 'setLocalFileRead', data: false })
    }
  }

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      style={{ paddingLeft: '9vw', paddingRight: '10.4vw' }}
    >
      <Navbar.Brand href="#home">
        <img style={{ width: '150px' }} src={logo} alt="logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav>
          {!hideFiles && (
            <Navbar.Brand href="#files" className="navLinks" onClick={displayAvailabledModels}>
              Files
            </Navbar.Brand>
          )}
          {!hideBilling && (
            <Navbar.Brand href="#billing" onClick={handleRedirection} className="navLinks">
              Billing
            </Navbar.Brand>
          )}
          {!hideLogout && currentUser !== null && (
            <Navbar.Brand onClick={handleLogout} className="navLinks">
              Logout
            </Navbar.Brand>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

function mapStateToProps(state) {
  return {
    loadState: state.LoadState,
  }
}

export default connect(mapStateToProps)(NavBar)

NavBar.defaultProps = {
  hideFiles: false,
  hideBilling: false,
  hideLogout: false,
}
