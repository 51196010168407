import * as THREE from 'three'
import RayysLinearDimension from './RaysLinearDimension'
import RayysFacingCamera from './RayysFacingCamera'
import './style.css'

export default class Measure {
  constructor(scene, renderer, camera, controls) {
    this.scene = scene
    this.renderer = renderer
    this.camera = camera
    this.canvas = renderer.domElement
    this.controls = controls
    // this.label;

    this.active = false
    this.position1 = new THREE.Vector3(0, 0, 0)
    this.position2 = new THREE.Vector3(0, 0, 0)

    const lineGeometry = new THREE.Geometry()
    lineGeometry.vertices.push(this.position1)
    lineGeometry.vertices.push(this.position2)
    const lineMaterial = new THREE.LineDashedMaterial({
      color: 0xff5555,
      linewidth: 10,
      side: THREE.DoubleSide,
      transparent: true,
      depthTest: false,
      scale: 10,
    })
    this.line = new THREE.Line(lineGeometry, lineMaterial)
    this.scene.add(this.line)

    this.point1 = new THREE.Mesh(
      new THREE.SphereBufferGeometry(1, 32, 32),
      new THREE.MeshPhongMaterial({ color: 0xffffff, side: THREE.DoubleSide })
    )
    window['point'] = this.point1 //@
    this.point1.visible = false
    this.scene.add(this.point1)
    this.point2 = this.point1.clone()
    this.point2.visible = false
    this.scene.add(this.point2)

    this.distanceSet = true

    this.labelDiv = document.querySelector('#labelDiv')
    this.labelDiv.style.visibility = 'hidden'

    this.pointRadius = 4

    this.controls.addEventListener('change', (e) => {
      if (!this.active) return
      const { x, y } = this.toScreenPosition(
        new THREE.Vector3().addVectors(this.position1, this.position2).divideScalar(2),
        this.camera
      )
      this.labelDiv.style.left = x
      this.labelDiv.style.top = y
    })
  }

  init(object, initialize = false) {
    if (!object) return
    this.disposeAll()
    this.object = object
    this.boxHelper = new THREE.BoxHelper(object, 0x888888)
    this.boxHelper.visible = false
    this.scene.add(this.boxHelper)

    const box = new THREE.Box3().setFromObject(object)
    const size = box.getSize(new THREE.Vector3()).length()
    this.point1.geometry.dispose()
    this.point2.geometry.dispose()
    this.point1.geometry = new THREE.SphereBufferGeometry(size / 50, 32, 32)
    this.point2.geometry = new THREE.SphereBufferGeometry(size / 50, 32, 32)
    this.dimensions = new THREE.Object3D()
    this.dimensions.visible = false
    this.scene.add(this.dimensions)

    // if(initialize){
    this.removeElementsByClass('dim')

    this.dim0 = new RayysLinearDimension(document.body, this.renderer, this.camera, size)
    this.dim1 = new RayysLinearDimension(document.body, this.renderer, this.camera, size)
    this.dim2 = new RayysLinearDimension(document.body, this.renderer, this.camera, size)
    this.facingCamera = new RayysFacingCamera()
    // }

    this.facingCamera.cb.facingDirChange.push((event) => {
      let facingDirY = this.facingCamera.dirs[0]
      let facingDirX = this.facingCamera.dirs[2]
      let facingDirZ = this.facingCamera.dirs[4]
      if (this.dim0.node !== undefined) {
        this.dim0.detach()
      }
      if (this.dim1.node !== undefined) {
        this.dim1.detach()
      }
      if (this.dim2.node !== undefined) {
        this.dim2.detach()
      }
      var bbox = this.object.geometry.boundingBox
      let from = new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z)
      let to = new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.min.z)
      let newDimension = this.dim0.create(from, to, facingDirX)
      this.dimensions.add(newDimension)

      from = new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z)
      to = new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.max.z)
      newDimension = this.dim1.create(from, to, facingDirY)
      this.dimensions.add(newDimension)

      from = new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z)
      to = new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.min.z)
      newDimension = this.dim2.create(from, to, facingDirZ)
      this.dimensions.add(newDimension)
    })
  }

  removeElementsByClass(className) {
    const elements = document.getElementsByClassName(className)
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0])
    }
  }

  disposeAll() {
    const objectsToDispose = [this.boxHelper, this.dimensions]
    objectsToDispose.forEach((obj) => this.Destroy(obj))
  }

  Destroy(mesh) {
    if (!mesh) return
    if (mesh.geometry) mesh.geometry.dispose()
    if (mesh.material) mesh.material.dispose()

    if (mesh.children.length > 0) {
      for (var i = 0; i < mesh.children.length; i++) {
        this.Destroy(mesh.children[i])
      }
    }
    this.scene.remove(mesh)
    mesh = undefined
  }

  changeScaleOfPoint(distance) {
    if (!this.active) return
    const scale = Math.sqrt(distance) / 50

    this.point1.scale.set(scale, scale, scale)
    this.point2.scale.set(scale, scale, scale)
  }

  adjustDimsVisibility() {
    if (!this.dim0.domElement || !this.dim1.domElement || !this.dim2.domElement || !this.labelDiv)
      return
    this.dim0.domElement.style.display = this.withinCanvas(
      this.getOffsetFromStyle(this.dim0.domElement.style.left),
      this.getOffsetFromStyle(this.dim0.domElement.style.top)
    )
      ? 'block'
      : 'none'
    this.dim1.domElement.style.display = this.withinCanvas(
      this.getOffsetFromStyle(this.dim1.domElement.style.left),
      this.getOffsetFromStyle(this.dim1.domElement.style.top)
    )
      ? 'block'
      : 'none'
    this.dim2.domElement.style.display = this.withinCanvas(
      this.getOffsetFromStyle(this.dim2.domElement.style.left),
      this.getOffsetFromStyle(this.dim2.domElement.style.top)
    )
      ? 'block'
      : 'none'
    this.labelDiv.style.display = this.withinCanvas(
      this.getOffsetFromStyle(this.labelDiv.style.left),
      this.getOffsetFromStyle(this.labelDiv.style.top),
      true
    )
      ? 'block'
      : 'none'
  }

  getOffsetFromStyle(str) {
    return parseInt(str.slice(0, -2)).toFixed(0)
  }

  withinCanvas(left, top, canvasChild = false) {
    if (!this.canvas) return false
    const offsetLeft = this.getOffset(this.canvas).left
    const offsetTop = this.getOffset(this.canvas).top
    const width = this.canvas.clientWidth
    const height = this.canvas.clientHeight

    if (
      left > (canvasChild ? 0 : offsetLeft) &&
      left < (canvasChild ? 0 : offsetLeft) + width &&
      top > (canvasChild ? 0 : offsetTop) &&
      top < (canvasChild ? 0 : offsetTop) + height
    )
      return true
    else return false
  }

  activate() {
    this.active = true

    if (!this.object) {
      console.warn('object is undefined')
      return
    }
    this.boxHelper.visible = true
    this.dimensions.visible = true
    if (!this.dim0.domElement || !this.dim1.domElement || !this.dim2.domElement) return
    this.dim0.domElement.style.display = 'block'
    this.dim1.domElement.style.display = 'block'
    this.dim2.domElement.style.display = 'block'
  }

  deactivate() {
    this.active = false
    this.boxHelper.visible = false
    this.dimensions.visible = false
    this.labelDiv.style.visibility = 'hidden'
    this.line.visible = false
    this.controls.enabled = true
    this.point1.visible = false
    this.point2.visible = false
    if (!this.dim0.domElement || !this.dim1.domElement || !this.dim2.domElement) return
    this.dim0.domElement.style.display = 'none'
    this.dim1.domElement.style.display = 'none'
    this.dim2.domElement.style.display = 'none'
  }

  mouseup(e) {
    let intersects = this.getIntersections(e)
    if (!this.active || intersects.length === 0) return
    let intersect = intersects[0]
    if (this.distanceSet) {
      this.position1.copy(intersect.point)
      this.position2.copy(intersect.point)
      this.distanceSet = false
      this.labelDiv.style.visibility = 'visible'
      this.line.visible = true

      this.point1.visible = true
      this.point2.visible = true

      this.point1.position.copy(intersect.point)
      this.controls.enabled = false
    } else {
      this.distanceSet = true
      this.controls.enabled = true
    }
  }

  onmousemove(e) {
    if (!this.active || this.distanceSet) return
    let intersects = this.getIntersections(e)
    if (intersects.length === 0) return
    let intersect = intersects[0]
    this.position2.copy(intersect.point)
    this.line.geometry.verticesNeedUpdate = true
    let distance = this.position1.distanceTo(this.position2)
    this.labelDiv.innerText = distance.toFixed(2) + 'mm'
    const { x, y } = this.toScreenPosition(
      new THREE.Vector3().addVectors(this.position1, this.position2).divideScalar(2),
      this.camera
    )
    this.labelDiv.style.left = x
    this.labelDiv.style.top = y

    const scaleDiv = document.querySelector('#realValue')
    if (scaleDiv) scaleDiv.value = distance.toFixed(2)

    this.point2.position.copy(intersect.point)
  }

  label_point(point, size) {
    // point is created and returned given its position and size

    var marker
    marker = new THREE.Mesh(
      new THREE.SphereGeometry(size, 10, 20),
      new THREE.MeshBasicMaterial({
        color: 0xff5555,
      })
    )
    marker.position.copy(point)
    this.scene.add(marker)
  }

  getIntersections(event) {
    // get intersection point when mouse click on the surface of model
    const offsetLeft = this.getOffset(this.canvas).left
    const offsetTop = this.getOffset(this.canvas).top
    const width = this.canvas.clientWidth
    const height = this.canvas.clientHeight

    const vector = new THREE.Vector2()
    vector.set(
      ((event.clientX - offsetLeft) / width) * 2 - 1,
      -((event.clientY - offsetTop) / height) * 2 + 1
    )
    const raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(vector, this.camera)

    let intersects
    intersects = raycaster.intersectObjects([this.scene.getObjectByName('object')], true)
    return intersects
  }

  toScreenPosition(position, camera) {
    // put html elements to the right position as screen changes

    // TODO: need to update this when resize window
    const widthHalf = 0.5 * this.canvas.clientWidth
    const heightHalf = 0.5 * this.canvas.clientHeight

    position.project(camera)

    position.x = position.x * widthHalf + widthHalf - 30
    position.y = -(position.y * heightHalf) + heightHalf - 15

    return {
      x: `${position.x}px`,
      y: `${position.y}px`,
    }
  }

  getOffset(el) {
    var _x = 0
    var _y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft
      _y += el.offsetTop - el.scrollTop
      el = el.offsetParent
    }
    return { top: _y, left: _x }
  }

  animate() {
    if (!this.facingCamera || !this.dim0 || !this.dim1 || !this.dim2 || !this.active) return
    this.facingCamera.check(this.camera)
    this.dim0.update(this.camera)
    this.dim1.update(this.camera)
    this.dim2.update(this.camera)
  }
}
