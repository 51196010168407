import React from 'react'
import { RiDeleteBin6Line } from 'react-icons/all'
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage'
import { loadObjFiles_firebase } from '../../3dView/main/main'
import { connect } from 'react-redux'
import { getFileType } from '../../../lib/utils'
import ReduxStore from '../../../Redux/Store'
import app from '../../../firebase'
import { useAuth } from '../../../contexts/AuthContext'

function Card(props) {
  const storage = getStorage()
  const db = app.firestore()
  const { currentUser } = useAuth()

  const onOpenModel = () => {
    ReduxStore.dispatch({ type: 'setLoadState', data: 'loading' })
    const listRef = ref(storage, props.modelPath)

    const files = []
    listAll(listRef)
      .then((res) => {
        const promises = res.items.map((itemref) => {
          return new Promise(async (resolve) => {
            const file = {
              url: await getDownloadURL(ref(storage, props.modelPath + '/' + itemref.name)),
              type: getFileType(itemref.name),
            }
            files.push(file)
            resolve(file)
          })
        })
        Promise.all(promises).then(() => {
          loadObjFiles_firebase(files)
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const onRemoveModel = async () => {
    if (window.confirm('Do you really want to remove the files?')) {
      db.collection('models').doc(currentUser.uid).collection('data').doc(props.id).delete()
      props.remove(props.id)
      // const docData = await db
      //   .collection('models')
      //   .doc(currentUser.uid)
      //   .collection('data')
      //   .doc(props.id)
      //   .get()
      //   .then((doc) => doc.exists && doc.data())
      // if (docData)
      //   db.collection('models').doc(currentUser.uid).collection('data').doc('abcd').set(docData)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        height: 100,
        width: '60%',
        minWidth: 230,
        backgroundColor: 'white',
        borderRadius: 10,
        margin: 'auto',
        marginBottom: 15,
        cursor: 'pointer',
      }}
    >
      <img
        src={props.previewImageURL}
        alt="preview"
        style={{ width: 80, height: 80, margin: 10, borderRadius: 8 }}
        onClick={onOpenModel}
      />
      <div onClick={onOpenModel} style={{ padding: 10, width: '70%' }}>
        <div style={{ fontSize: 20, fontWeight: 600 }}>{props.name}</div>
        <div>{props.time}</div>
        {props.processed ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                width: 7,
                borderRadius: '7px',
                margin: '8px 2px',
                backgroundColor: 'green',
              }}
            />
            <div style={{ fontSize: 16, fontWeight: 500, color: 'green' }}>Completed</div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                width: 7,
                borderRadius: 7,
                margin: '8px 2px',
                backgroundColor: 'purple',
              }}
            />
            <div style={{ fontSize: 16, fontWeight: 500, color: 'purple' }}>Processing</div>
          </div>
        )}
      </div>
      {props.processed && (
        <div style={{ margin: 'auto', marginRight: 20 }} onClick={onRemoveModel}>
          <RiDeleteBin6Line style={{ width: 20, height: 20 }} />
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    loadState: state.LoadState,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLoadState: (value) => dispatch({ type: 'setLoadState', data: value }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Card)
