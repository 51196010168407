import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import logo from '../../assets/img/logo.png'
import r3dent from '../../assets/img/r3dent_big.svg'
import { Typography } from '@mui/material'

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage('')
      setError('')
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage('Check your inbox for further instructions')
    } catch {
      setError('Failed to reset password')
    }

    setLoading(false)
  }

  return (
    <section className="forgotPassword">
      <div
        style={{
          textAlign: 'center',
          height: '13vh',
          fontSize: '40px',
          fontWeight: '600',
          display: 'flex',
          justifyContent: 'center',
          color: '#fff',
        }}
      >
        <img src={r3dent} style={{ width: '200px' }} alt="logo" />
      </div>
      <Card className="authCard">
        <Card.Body>
          <Typography
            variant="h4"
            align="left"
            className="mb-4 cardheader"
          >
            Reset Password
          </Typography>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <p style={{ fontSize: '16px' }}>
            Enter your email and we send you an email with a link to reset your password
          </p>
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="email@email.com" ref={emailRef} required />
            </Form.Group>
            <Button disabled={loading} variant="dark" className="w-100 mt-4 send-reset-email" type="submit">
              Send Reset Email
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="made-by">
        made by
        <br />
        <img src={logo} alt="logo img"></img>
      </div>
    </section>
  )
}
