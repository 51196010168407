import React from 'react'
import { Row, Col } from 'react-bootstrap'
import NavBar from 'components/UI/NavBar/Navbar'
import Header from '../UI/Header'
import { Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import parse from 'html-react-parser'

const PrivacyPolicyData = [
  {
    policy_name: 'Introduction',
    children: [
      {
        text: `Welcome to <b>r3volutionD AG.</b>`,
      },
      {
        text: `r3volutionD AG (“us”, “we”, or “our”) operates r3Dent.info (hereinafter referred to as
          “<b>Service</b>”).`,
      },
      {
        text: `Our Privacy Policy governs your visit to r3Dent.info, and explains how we collect, safeguard
        and disclose information that results from your use of our Service.`,
      },
      {
        text: `We use your data to provide and improve Service. By using Service, you agree to the
        collection and use of information in accordance with this policy. Unless otherwise defined in
        this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions.`,
      },
      {
        text: `Our Terms and Conditions (“<b>Terms</b>”) govern all use of our Service and together with the
        Privacy Policy constitutes your agreement with us (“<b>agreement</b>”).`,
      },
    ],
  },
  {
    policy_name: 'Definitions',
    children: [
      {
        text: `<b>SERVICE</b> means the r3Dent.info website operated by r3volutionD AG.`,
      },
      {
        text: `<b>PERSONAL DATA</b> means data about a living individual who can be identified from those data
        (or from those and other information either in our possession or likely to come into our
        possession).`,
      },
      {
        text: `<b>USAGE DATA</b> is data collected automatically either generated by the use of Service or from
        Service infrastructure itself (for example, the duration of a page visit).`,
      },
      {
        text: `<b>COOKIES</b> are small files stored on your device (computer or mobile device).`,
      },
      {
        text: `<b>DATA CONTROLLER</b> means a natural or legal person who (either alone or jointly or in
          common with other persons) determines the purposes for which and the manner in which any
          personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a
          Data Controller of your data.`,
      },
      {
        text: `<b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or legal person who
        processes the data on behalf of the Data Controller. We may use the services of various
        Service Providers in order to process your data more effectively.`,
      },
      {
        text: `<b>DATA SUBJECT </b>is any living individual who is the subject of Personal Data.`,
      },
      {
        text: `<b>THE USER</b> is the individual using our Service. The User corresponds to the Data Subject,
        who is the subject of Personal Data.`,
      },
    ],
  },
  {
    policy_name: 'Information Collection and Use',
    children: [
      {
        text: `We collect several different types of information for various purposes to provide and improve
        our Service to you.`,
      },
    ],
  },
  {
    policy_name: 'Types of Data Collected',
    children: [
      {
        text: `<b>Personal Data</b><br/>
        While using our Service, we may ask you to provide us with certain personally identifiable
        information that can be used to contact or identify you (“Personal Data”). Personally
        identifiable information may include, but is not limited to:`,
      },
      {
        text: `(a) Email address`,
      },
      {
        text: `(b) First name and last name`,
      },
      {
        text: `(c) Address, State, Province, ZIP/Postal code, City`,
      },
      {
        text: `(d) Cookies and Usage Data`,
      },
      {
        text: `We may use your Personal Data to contact you with newsletters, marketing or promotional
        materials and other information that may be of interest to you. You may opt out of receiving
        any, or all, of these communications from us by following the unsubscribe link or by emailing
        at info@r3volutionD.com.`,
      },
      {
        text: `<b>Usage Data</b><br />
        We may also collect information that your browser sends whenever you visit our Service or
        when you access Service by or through a mobile device (“<b>Usage Data</b>”).`,
      },
      {
        text: `This Usage Data may include information such as your computer's Internet Protocol address
        (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the
        time and date of your visit, the time spent on those pages, unique device identifiers and other
        diagnostic data.`,
      },
      {
        text: `When you access Service with a mobile device, this Usage Data may include information such
        as the type of mobile device you use, your mobile device unique ID, the IP address of your
        mobile device, your mobile operating system, the type of mobile Internet browser you use,
        unique device identifiers and other diagnostic data.`,
      },
      {
        text: `<b>Tracking Cookies Data</b><br />
        We use cookies and similar tracking technologies to track the activity on our Service and we
        hold certain information.`,
      },
      {
        text: `Cookies are files with a small amount of data which may include an anonymous unique
        identifier. Cookies are sent to your browser from a website and stored on your device. Other
        tracking technologies are also used such as beacons, tags and scripts to collect and track
        information and to improve and analyze our Service.`,
      },
      {
        text: `You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
        However, if you do not accept cookies, you may not be able to use some portions of our
        Service.`,
      },
      {
        text: `Examples of Cookies we use:`,
      },
      {
        text: `(a) <b>Session Cookies</b>: We use Session Cookies to operate our Service.`,
      },
      {
        text: `(b) <b>Preference Cookies</b>: We use Preference Cookies to remember your preferences and
        various settings.`,
      },
      {
        text: `(c) <b>Security Cookies</b>: We use Security Cookies for security purposes.`,
      },
      {
        text: `(d) <b>Advertising Cookies</b>: Advertising Cookies are used to serve you with advertisements
        that may be relevant to you and your interests.`,
      },
    ],
  },
  {
    policy_name: 'Use of Data',
    children: [
      {
        text: `r3volutionD AG uses the collected data for various purposes:`,
      },
      {
        text: `(a) to provide and maintain our Service;`,
      },
      {
        text: `(b) to notify you about changes to our Service;`,
      },
      {
        text: `(c) to allow you to participate in interactive features of our Service when you choose to do so;`,
      },
      {
        text: `(d) to provide customer support;`,
      },
      {
        text: `(e) to gather analysis or valuable information so that we can improve our Service;`,
      },
      {
        text: `(f) to monitor the usage of our Service;`,
      },
      {
        text: `(g) to detect, prevent and address technical issues;`,
      },
      {
        text: `(h) to fulfill any other purpose for which you provide it;`,
      },
      {
        text: `(i) to carry out our obligations and enforce our rights arising from any contracts entered into
        between you and us, including for billing and collection;`,
      },
      {
        text: `(j) to provide you with notices about your account and/or subscription, including expiration
        and renewal notices, email-instructions, etc.;`,
      },
      {
        text: `(k) to provide you with news, special offers and general information about other goods,
        services and events which we offer that are similar to those that you have already
        purchased or enquired about unless you have opted not to receive such information;`,
      },
      {
        text: `(l) in any other way we may describe when you provide the information;`,
      },
      {
        text: `(m) for any other purpose with your consent.`,
      },
    ],
  },
  {
    policy_name: 'Refunds',
    children: [
      {
        text: `Except when required by law, paid Subscription fees are non-refundable.`,
      },
    ],
  },
  {
    policy_name: 'Retention of Data',
    children: [
      {
        text: `We will retain your Personal Data only for as long as is necessary for the purposes set out in
        this Privacy Policy. We will retain and use your Personal Data to the extent necessary to
        comply with our legal obligations (for example, if we are required to retain your data to comply
        with applicable laws), resolve disputes, and enforce our legal agreements and policies.`,
      },
      {
        text: `We will also retain Usage Data for internal analysis purposes. Usage Data is generally
        retained for a shorter period, except when this data is used to strengthen the security or to
        improve the functionality of our Service, or we are legally obligated to retain this data for longer
        time periods.`,
      },
    ],
  },
  {
    policy_name: 'Transfer of Data',
    children: [
      {
        text: `Your information, including Personal Data, may be transferred to – and maintained on –
        computers located outside of your state, province, country or other governmental jurisdiction
        where the data protection laws may differ from those of your jurisdiction.`,
      },
      {
        text: `If you are located outside United States and choose to provide information to us, please note
        that we transfer the data, including Personal Data, to United States and process it there.`,
      },
      {
        text: `Your consent to this Privacy Policy followed by your submission of such information
        represents your agreement to that transfer.`,
      },
      {
        text: `r3volutionD AG will take all the steps reasonably necessary to ensure that your data is treated
        securely and in accordance with this Privacy Policy and no transfer of your Personal Data will
        take place to an organisation or a country unless there are adequate controls in place
        including the security of your data and other personal information.`,
      },
    ],
  },
  {
    policy_name: 'Disclosure of Data',
    children: [
      {
        text: `We may disclose personal information that we collect, or you provide:`,
      },
      {
        text: `(a) <b>Disclosure for Law Enforcement</b>.
        Under certain circumstances, we may be required to disclose your Personal Data if
        required to do so by law or in response to valid requests by public authorities.`,
      },
      {
        text: `(a) <b>Disclosure for Law Enforcement</b>.
        Under certain circumstances, we may be required to disclose your Personal Data if
        required to do so by law or in response to valid requests by public authorities.`,
      },
      {
        text: `(b) <b>Business Transaction</b>.
        If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal
Data may be transferred.`,
      },
      {
        text: `(c) <b>Other cases. We may disclose your information also:</b>.
        Under certain circumstances, we may be required to disclose your Personal Data if
        required to do so by law or in response to valid requests by public authorities.`,
      },
      {
        text: `&ensp;(i)to our subsidiaries and affiliates;`,
      },
    ],
  },
  {
    policy_name: 'Security of Data',
    children: [
      {
        text: `The security of your data is important to us but remember that no method of transmission over
        the Internet or method of electronic storage is 100% secure. While we strive to use
        commercially acceptable means to protect your Personal Data, we cannot guarantee its
        absolute security.`,
      },
    ],
  },
  {
    policy_name: 'Your Data Protection Rights Under General Data Protection Regulation (GDPR)',
    children: [
      {
        text: `If you are a resident of the European Union (EU) and European Economic Area (EEA), you
        have certain data protection rights, covered by GDPR. – See more at <a href="https://eur-
        lex.europa.eu/eli/reg/2016/679/oj">https://eur-lex.europa.eu/eli/reg/2016/679/oj</a>`,
      },
      {
        text: `We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of
        your Personal Data.`,
      },
      {
        text: `If you wish to be informed what Personal Data we hold about you and if you want it to be
        removed from our systems, please email us at info@r3volutionD.com.`,
      },
      {
        text: `In certain circumstances, you have the following data protection rights:`,
      },
      {
        text: `(a) the right to access, update or to delete the information we have on you;`,
      },
      {
        text: `(b) the right of rectification. You have the right to have your information rectified if that
        information is inaccurate or incomplete;`,
      },
      {
        text: `(c) the right to object. You have the right to object to our processing of your Personal Data;`,
      },
      {
        text: `(d) the right of restriction. You have the right to request that we restrict the processing of your
        personal information;`,
      },
      {
        text: `(e) the right to data portability. You have the right to be provided with a copy of your Personal
        Data in a structured, machine-readable and commonly used format;`,
      },
      {
        text: `(f) the right to withdraw consent. You also have the right to withdraw your consent at any time
        where we rely on your consent to process your personal information;`,
      },
      {
        text: `Please note that we may ask you to verify your identity before responding to such requests.
        Please note, we may not able to provide Service without some necessary data.`,
      },
      {
        text: `You have the right to complain to a Data Protection Authority about our collection and use of
        your Personal Data. For more information, please contact your local data protection authority
        in the European Economic Area (EEA).`,
      },
    ],
  },
  {
    policy_name:
      'Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)',
    children: [
      {
        text: `CalOPPA is the first state law in the nation to require commercial websites and online services
        to post a privacy policy. The law’s reach stretches well beyond California to require a person
        or company in the United States (and conceivable the world) that operates websites collecting
        personally identifiable information from California consumers to post a conspicuous privacy
        policy on its website stating exactly the information being collected and those individuals with
        whom it is being shared, and to comply with this policy. – See more at:
        <A href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-
        protection-act-caloppa-3/">https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-
        protection-act-caloppa-3/</a>`,
      },
      {
        text: `According to CalOPPA we agree to the following:`,
      },
      {
        text: `(a) users can visit our site anonymously;`,
      },
      {
        text: `(b) our Privacy Policy link includes the word “Privacy”, and can easily be found on the page
        specified above on the home page of our website;`,
      },
      {
        text: `(c) users will be notified of any privacy policy changes on our Privacy Policy Page;`,
      },
      {
        text: `(d) users are able to change their personal information by emailing us at info@r3volutionD.com.`,
      },
      {
        text: `Our Policy on “Do Not Track” Signals:`,
      },
      {
        text: `We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do
        Not Track browser mechanism is in place. Do Not Track is a preference you can set in your
        web browser to inform websites that you do not want to be tracked.`,
      },
      {
        text: `You can enable or disable Do Not Track by visiting the Preferences or Settings page of your
        web browser.`,
      },
    ],
  },
  {
    policy_name: 'Your Data Protection Rights under the California Consumer Privacy Act (CCPA)',
    children: [
      {
        text: `If you are a California resident, you are entitled to learn what data we collect about you, ask
        to delete your data and not to sell (share) it. To exercise your data protection rights, you can
        make certain requests and ask us:`,
      },
      {
        text: `&emsp;(a) <b>What personal information we have about you</b>. If you make this request, we will return
        to you:`,
      },
      {
        text: `&emsp;&ensp;(i) The categories of personal information we have collected about you.`,
      },
      {
        text: `&emsp;&ensp;(ii) The categories of sources from which we collect your personal information.`,
      },
      {
        text: `&emsp;&ensp;(iii) The business or commercial purpose for collecting or selling your personal
        information.`,
      },
      {
        text: `&emsp;&ensp;(iv) The categories of third parties with whom we share personal information.`,
      },
      {
        text: `&emsp;&ensp;(v) The specific pieces of personal information we have collected about you.`,
      },
      {
        text: `&emsp;&ensp;(vi) A list of categories of personal information that we have sold, along with the category
        of any other company we sold it to. If we have not sold your personal information, we
        will inform you of that fact.`,
      },
      {
        text: `&emsp;&ensp;(vii) A list of categories of personal information that we have disclosed for a business
        purpose, along with the category of any other company we shared it with.`,
      },
      {
        text: `Please note, you are entitled to ask us to provide you with this information up to two times
        in a rolling twelve-month period. When you make this request, the information provided
        may be limited to the personal information we collected about you in the previous
        12 months.`,
      },
      {
        text: `&emsp;(b) <b>To delete your personal information</b>. If you make this request, we will delete the
        personal information we hold about you as of the date of your request from our records
        and direct any service providers to do the same. In some cases, deletion may be
        accomplished through de-identification of the information. If you choose to delete your
        personal information, you may not be able to use certain functions that require your
        personal information to operate.`,
      },
      {
        text: `&emsp;(c) <b>To stop selling your personal information</b>. We don't sell or rent your personal
        information to any third parties for any purpose. You are the only owner of your Personal
        Data and can request disclosure or deletion at any time.`,
      },
      {
        text: `&emsp;&emsp;&ensp;Please note, if you ask us to delete or stop selling your data, it may impact your experience
        with us, and you may not be able to participate in certain programs or membership services
        which require the usage of your personal information to function. But in no circumstances,
        we will discriminate against you for exercising your rights.`,
      },
      {
        text: `&emsp;&emsp;&ensp;To exercise your California data protection rights described above, please send your
        request(s) by one of the following means:`,
      },
      {
        text: `&emsp;&emsp;&ensp;By email: info@r3volutionD.com`,
      },
      {
        text: `Your data protection rights, described above, are covered by the CCPA, short for the
        California Consumer Privacy Act. To find out more, visit the official California Legislative
        Information website. The CCPA took effect on 01/01/2020.`,
      },
    ],
  },
  {
    policy_name: 'Service Providers',
    children: [
      {
        text: `We may employ third party companies and individuals to facilitate our Service (“<b>Service
          Providers”</b>), provide Service on our behalf, perform Service-related services or assist us in
          analysing how our Service is used.`,
      },
      {
        text: `These third parties have access to your Personal Data only to perform these tasks on our
        behalf and are obligated not to disclose or use it for any other purpose.`,
      },
    ],
  },
  {
    policy_name: 'Analytics',
    children: [
      {
        text: `We may use third-party Service Providers to monitor and analyze the use of our Service.`,
      },
      {
        text: `<b>Google Analytics</b><br />
        Google Analytics is a web analytics service offered by Google that tracks and reports website
        traffic. Google uses the data collected to track and monitor the use of our Service. This data
        is shared with other Google services. Google may use the collected data to contextualise and
        personalise the ads of its own advertising network.`,
      },
      {
        text: `For more information on the privacy practices of Google, please visit the Google Privacy
        Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>`,
      },
      {
        text: `We also encourage you to review the Google's policy for safeguarding your data:
        <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.`,
      },
      {
        text: `<b>Firebase</b><br/>
        Firebase is analytics service provided by Google Inc.`,
      },
      {
        text: `You may opt-out of certain Firebase features through your mobile device settings, such as
        your device advertising settings or by following the instructions provided by Google in their
        Privacy Policy: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>`,
      },
      {
        text: `For more information on what type of information Firebase collects, please visit the Google
        Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>`,
      },
    ],
  },
  {
    policy_name: 'CI/CD tools',
    children: [
      {
        text: `We may use third-party Service Providers to automate the development process of our
        Service.`,
      },
      {
        text: `<b>GitHub</b>`,
      },
      {
        text: `GitHub is provided by GitHub, Inc.`,
      },
      {
        text: `GitHub is a development platform to host and review code, manage projects, and build
        software.`,
      },
      {
        text: `For more information on what data GitHub collects for what purpose and how the protection
        of the data is ensured, please visit GitHub Privacy Policy page: <a href="https://help.github.com/en/articles/github-privacy-statement">https://help.github.com/en/articles/github-privacy-statement</a>.`,
      },
    ],
  },
  {
    policy_name: 'Payments',
    children: [
      {
        text: `We may provide paid products and/or services within Service. In that case, we use third-party
        services for payment processing (e.g. payment processors).`,
      },
      {
        text: `We will not store or collect your payment card details. That information is provided directly to
        our third-party payment processors whose use of your personal information is governed by
        their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as
        managed by the PCI Security Standards Council, which is a joint effort of brands like Visa,
        Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure
        handling of payment information.`,
      },
      {
        text: `The payment processors we work with are:`,
      },
      {
        text: `<b>Stripe:</b><br/>
        Their Privacy Policy can be viewed at: <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>`,
      },
    ],
  },
  {
    policy_name: 'Links to Other Sites',
    children: [
      {
        text: `Our Service may contain links to other sites that are not operated by us. If you click a third
        party link, you will be directed to that third party's site. We strongly advise you to review the
        Privacy Policy of every site you visit.`,
      },
      {
        text: `We have no control over and assume no responsibility for the content, privacy policies or
        practices of any third party sites or services.`,
      },
    ],
  },
  {
    policy_name: 'Changes to This Privacy Policy',
    children: [
      {
        text: `We may update our Privacy Policy from time to time. We will notify you of any changes by
        posting the new Privacy Policy on this page.`,
      },
      {
        text: `We will let you know via email and/or a prominent notice on our Service, prior to the change
      becoming effective and update “effective date” at the top of this Privacy Policy.`,
      },
      {
        text: `You are advised to review this Privacy Policy periodically for any changes. Changes to this
        Privacy Policy are effective when they are posted on this page.`,
      },
    ],
  },
  {
    policy_name: 'Contact Us',
    children: [
      {
        text: `If you have any questions about this Privacy Policy, please contact us:`,
      },
      {
        text: `By email: info@r3volutionD.com.`,
      },
    ],
  },
]

const PrivacyPolicy = () => {
  const history = useHistory()
  return (
    <div className="privacy-policy-container">
      <Row>
        <Col>
          <NavBar hideFiles hideBilling />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <Header onBack={() => history.goBack()} />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <Typography variant="h4" align="center" className="!font-semibold heading">
            PRIVACY POLICY
          </Typography>
          <Typography variant="body2" align="left" style={{ marginBottom: '20px' }}>
            Effective date: 02/15/2022
          </Typography>
          <ol type="1">
            {PrivacyPolicyData.map((policyCategory, categoryIndex) => (
              <li>
                <Typography
                  variant="body2"
                  align="left"
                  style={{ marginBottom: '20px', textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  {policyCategory.policy_name}
                </Typography>
                {policyCategory.children.map((content) => (
                  <Typography variant="body2" align="justify" style={{ marginBottom: '20px' }}>
                    {parse(content.text)}
                  </Typography>
                ))}
              </li>
            ))}
          </ol>
        </Col>
      </Row>
    </div>
  )
}

export default PrivacyPolicy
