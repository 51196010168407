import { ThemeProvider } from '@mui/material/styles'
import { getRemoteConfig } from 'firebase/remote-config'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AppTheme from 'themes/AppTheme'
import { AuthProvider } from '../../contexts/AuthContext'
import Subscription from '../Subscription/Subscription'
import AddressForm from './AddressForm'
import Dashboard from './Dashboard'
import ForgotPassword from './ForgotPassword'
import Login from './Login'
import './main.css'
import PrivateRoute from './PrivateRoute'
import Reset from './Reset'
import ResetSuccess from './ResetSuccess'
import Signup from './Signup'
import SignupIncomplete from './SignupIncomplete'
import UpdateProfile from './UpdateProfile'
import PrivacyPolicy from './PrivacyPolicy'
import TermsConditions from './TermsConditions'

const remoteConfig = getRemoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = 3600000
remoteConfig.defaultConfig = {
  recommended_subscription_card: '',
  recommended_subscription_card_id: 1,
}

function App() {
  return (
    <div className="auth">
      <div className="auth-page">
        <Router>
          <AuthProvider>
            <ThemeProvider theme={AppTheme}>
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />

                <PrivateRoute path="/update-profile" component={UpdateProfile} />
                <Route path="/signup" component={Signup} />
                <Route path="/address/:id" component={AddressForm} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset" component={Reset} />
                <Route path="/resetSuccess" component={ResetSuccess} />
                <Route path="/terms-conditions" component={TermsConditions} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />

                <PrivateRoute exact path="/subscribe" component={Subscription} />
                <Route exact path="/signupincomplete" component={SignupIncomplete} />
              </Switch>
            </ThemeProvider>
          </AuthProvider>
        </Router>
      </div>
    </div>
  )
}

export default App
