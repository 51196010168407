import React, { useState } from 'react'
import { Form, Card, Alert, Spinner } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import 'firebase/compat/firestore'
import app from '../../firebase'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { Button } from '@mui/material'
import r3dent from '../../assets/img/r3dent_big.svg'

export default function AddressForm() {
  const [addressInfo, setAddressInfo] = useState({
    name: '',
    clinic: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
  })

  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const db = app.firestore()

  const history = useHistory()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  let { id } = useParams()
  async function handleSubmit(e) {
    e.preventDefault()
    setLoading(true)

    // Add the address to Firestore users/{uid}
    addAddress()
  }

  const addAddress = async () => {
    let saveUserAddress = db.collection('users').doc(id)

    try {
      await saveUserAddress.set(addressInfo).then(() => {
        history.push(`/subscribe`)
      })
    } catch (error) {
      setLoading(false)
      setError('Something went wrong, please try again or contact support!')
      console.log(error)
    }
  }

  const handleAddressChange = (e) => {
    const value = e.target.value

    setAddressInfo({ ...addressInfo, [e.target.name]: value })
  }
  return (
    <section className="addressForm shadow-none">
      <div
        style={{
          textAlign: 'center',
          height: '13vh',
          fontSize: '40px',
          fontWeight: '600',
          display: 'flex',
          justifyContent: 'center',
          color: '#fff',
        }}
      >
        <img src={r3dent} style={{ width: '200px' }} alt="logo" />
      </div>
      <Card className="authCard">
        <Card.Body>
          <Typography variant="h4" align="left" className="mb-4 cardheader">
            Address
          </Typography>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label className="required-field">Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={addressInfo.name}
                onChange={handleAddressChange}
                placeholder="Name"
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="mt-3">Business name</Form.Label>
              <Form.Control
                type="text"
                name="clinic"
                value={addressInfo.clinic}
                onChange={handleAddressChange}
                style={{ backgroundColor: 'red' }}
                placeholder="Business name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="mt-3 required-field">Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={addressInfo.address}
                onChange={handleAddressChange}
                placeholder="Street and number"
                required
              />
            </Form.Group>
            <Grid container spacing={!isSmScreen ? 0 : 2}>
              <Grid item xs={12} sm={7}>
                <Form.Group>
                  <Form.Label className="mt-3 required-field">City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={addressInfo.city}
                    onChange={handleAddressChange}
                    placeholder="City"
                    required
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Form.Group>
                  <Form.Label className="mt-3 required-field">Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="postalCode"
                    value={addressInfo.postalCode}
                    onChange={handleAddressChange}
                    placeholder="Postal Code"
                    required
                  />
                </Form.Group>
              </Grid>
            </Grid>

            <Form.Group>
              <Form.Label className="mt-3 required-field">Country</Form.Label>
              <Form.Control
                type="text"
                name="country"
                value={addressInfo.country}
                onChange={handleAddressChange}
                placeholder="Country"
                required
              />
            </Form.Group>
            <Button
              variant="contained"
              className="w-100 mt-4 save-address-button"
              type="submit"
              color="dark"
            >
              {loading ? (
                <Spinner animation="border" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                'Continue'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="made-by">
        made by
        <br />
        <img src={logo} alt="logo img"></img>
      </div>
    </section>
  )
}
