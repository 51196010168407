import { threedManager } from './threeDManager'

export class RENDERER {
  constructor(options) {
    this.createThreedManager()
  }

  createThreedManager() {
    this.threedManager = new threedManager()
  }
}
