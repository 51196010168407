export default class History {
  constructor(threeDManager) {
    this.undos = []
    this.threeDManager = threeDManager
  }

  execute(command) {
    this.undos.push(command)
    if (this.undos.length > 5) this.undos.shift()
  }

  undo() {
    if (this.undos.length === 0) return
    const command = this.undos.pop()
    if (command) {
      if (command.category === 'scale') {
        this.threeDManager.scaleUndo(1 / command.value)
      } else if (
        command.category === 'cut' ||
        command.category === 'crop' ||
        command.category === 'hollow'
      ) {
        this.threeDManager.mainObject.geometry.dispose()
        this.threeDManager.mainObject.geometry = command.value
        this.threeDManager.setcontent()
      }
    }
  }
}
