import React from 'react'
import { Row, Col } from 'react-bootstrap'
import NavBar from 'components/UI/NavBar/Navbar'
import Header from '../UI/Header'
import { Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import parse from 'html-react-parser'

const TermsConditionData = [
  {
    term_name: 'Introduction',
    children: [
      {
        text: `Welcome to <b>r3volutionD AG</b> (“<b>Company</b>”, “</b>we</b>”, “</b>our</b>”, “</b>us</b>”)! As you have just clicked our
        Terms of Service, please pause, grab a cup of coffee and carefully read the following pages.
        It will take you approximately 20 minutes.`,
      },
      {
        text: `These Terms of Service (“<b>Terms</b>”, “<b>Terms of Service</b>”) govern your use of our web pages
        located at r3Dent.app operated by r3volutionD AG.`,
      },
      {
        text: `Our Privacy Policy also governs your use of our Service and explains how we collect,
        safeguard and disclose information that results from your use of our web pages. Please read
        it here r3dent.app/privacy.`,
      },
      {
        text: `Your agreement with us includes these Terms and our Privacy Policy (“<b>Agreements</b>”). You
        acknowledge that you have read and understood Agreements, and agree to be bound of them.`,
      },
      {
        text: `If you do not agree with (or cannot comply with) Agreements, then you may not use the
        Service, but please let us know by emailing at info@r3volutionD.com so we can try to find a
        solution. These Terms apply to all visitors, users and others who wish to access or use
        Service.`,
      },
      {
        text: `Thank you for being responsible.`,
      },
    ],
  },
  {
    term_name: 'Communications',
    children: [
      {
        text: `By creating an Account on our Service, you agree to subscribe to newsletters, marketing or
        promotional materials and other information we may send. However, you may opt out of
        receiving any, or all, of these communications from us by following the unsubscribe link or by
        emailing at.`,
      },
    ],
  },
  {
    term_name: 'Communications',
    children: [
      {
        text: `Some parts of Service are billed on a subscription basis (“<b>Subscription(s)</b>”). You will be billed
        in advance on a recurring and periodic basis (“<b>Billing Cycle</b>”). Billing cycles are set either on
        a monthly or annual basis, depending on the type of subscription plan you select when
        purchasing a Subscription.`,
      },
      {
        text: `At the end of each Billing Cycle, your Subscription will automatically renew under the exact
        same conditions unless you cancel it or r3volutionD AG cancels it. You may cancel your
        Subscription renewal either through your online account management page or by
        contacting r3volutionD AG customer support team.`,
      },
      {
        text: `A valid payment method, including credit card, is required to process the payment for your
        subscription. You shall provide r3volutionD AG with accurate and complete billing information
        including full name, address, state, zip code, telephone number, and a valid payment method
        information. By submitting such payment information, you automatically authorize r3volutionD
        AG to charge all Subscription fees incurred through your account to any such payment
        instruments.`,
      },
      {
        text: `Should automatic billing fail to occur for any reason, r3volutionD AG will issue an electronic
        invoice indicating that you must proceed manually, within a certain deadline date, with the full
        payment corresponding to the billing period as indicated on the invoice.`,
      },
    ],
  },
  {
    term_name: 'Free Trial',
    children: [
      {
        text: `r3volutionD AG may, at its sole discretion, offer a Subscription with a free trial for a limited
        period of time (“<b>Free Trial</b>”).`,
      },
      {
        text: `You may be required to enter your billing information in order to sign up for Free Trial.`,
      },
      {
        text: `If you do enter your billing information when signing up for Free Trial, you will not be charged
        by r3volutionD AG until Free Trial has expired. On the last day of Free Trial period, unless you
        cancelled your Subscription, you will be automatically charged the applicable Subscription
        fees for the type of Subscription you have selected.`,
      },
      {
        text: `At any time and without notice, r3volutionD AG reserves the right to (i) modify Terms of
        Service of Free Trial offer, or (ii) cancel such Free Trial offer.`,
      },
    ],
  },
  {
    term_name: 'Fee Changes',
    children: [
      {
        text: `r3volutionD AG, in its sole discretion and at any time, may modify Subscription fees for the
        Subscriptions. Any Subscription fee change will become effective at the end of the then-
        current Billing Cycle.`,
      },
      {
        text: `r3volutionD AG will provide you with a reasonable prior notice of any change in Subscription
        fees to give you an opportunity to terminate your Subscription before such change becomes
        effective.`,
      },
      {
        text: `Your continued use of Service after Subscription fee change comes into effect constitutes
        your agreement to pay the modified Subscription fee amount.`,
      },
    ],
  },
  {
    term_name: 'Refunds',
    children: [
      {
        text: `Except when required by law, paid Subscription fees are non-refundable.`,
      },
    ],
  },
  {
    term_name: 'Content',
    children: [
      {
        text: `Our Service allows you to post, link, store, share and otherwise make available certain
        information, text, graphics, videos, or other material (“<b>Content</b>”). You are responsible for
        Content that you post on or through Service, including its legality, reliability, and
        appropriateness.`,
      },
      {
        text: `By posting Content on or through Service, You represent and warrant that: (i) Content is yours
        (you own it) and/or you have the right to use it and the right to grant us the rights and license
        as provided in these Terms, and (ii) that the posting of your Content on or through Service
        does not violate the privacy rights, publicity rights, copyrights, contract rights or any other
        rights of any person or entity. We reserve the right to terminate the account of anyone found
        to be infringing on a copyright.`,
      },
      {
        text: `You retain any and all of your rights to any Content you submit, post or display on or through
        Service and you are responsible for protecting those rights. We take no responsibility and
        assume no liability for Content you or any third party posts on or through Service. However,
        by posting Content using Service you grant us the right and license to use, modify, publicly
        perform, publicly display, reproduce, and distribute such Content on and through Service. You
        agree that this license includes the right for us to make your Content available to other users
        of Service, who may also use your Content subject to these Terms.`,
      },
      {
        text: `r3volutionD AG has the right but not the obligation to monitor and edit all Content provided by
        users.`,
      },
      {
        text: `In addition, Content found on or through this Service are the property of r3volutionD AG or
        used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy,
        or use said Content, whether in whole or in part, for commercial purposes or for personal gain,
        without express advance written permission from us.`,
      },
    ],
  },
  {
    term_name: 'Prohibited Uses',
    children: [
      {
        text: `You may use Service only for lawful purposes and in accordance with Terms. You agree not
        to use Service:`,
      },
      {
        text: `&emsp;(a) In any way that violates any applicable national or international law or regulation.`,
      },
      {
        text: `&emsp;(b) For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way
        by exposing them to inappropriate content or otherwise.`,
      },
      {
        text: `&emsp;(c) To transmit, or procure the sending of, any advertising or promotional material, including
        any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.`,
      },
      {
        text: `&emsp;(d) To impersonate or attempt to impersonate Company, a Company employee, another user,
        or any other person or entity.`,
      },
      {
        text: `&emsp;(e) In any way that infringes upon the rights of others, or in any way is illegal, threatening,
        fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful
        purpose or activity.`,
      },
      {
        text: `&emsp;(f) To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of
        Service, or which, as determined by us, may harm or offend Company or users of Service
        or expose them to liability.`,
      },
      {
        text: `Additionally, you agree not to:`,
      },
      {
        text: `&emsp;(a) Use Service in any manner that could disable, overburden, damage, or impair Service or
        interfere with any other party’s use of Service, including their ability to engage in real time
        activities through Service.`,
      },
      {
        text: `&emsp;(b) Use any robot, spider, or other automatic device, process, or means to access Service for
        any purpose, including monitoring or copying any of the material on Service.`,
      },
      {
        text: `&emsp;(c) Use any manual process to monitor or copy any of the material on Service or for any other
        unauthorized purpose without our prior written consent.`,
      },
      {
        text: `&emsp;(d) Use any device, software, or routine that interferes with the proper working of Service.`,
      },
      {
        text: `&emsp;(e) Introduce any viruses, trojan horses, worms, logic bombs, or other material which is
        malicious or technologically harmful.`,
      },
      {
        text: `&emsp;(f) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of
        Service, the server on which Service is stored, or any server, computer, or database
        connected to Service.`,
      },
      {
        text: `&emsp;(g) Attack Service via a denial-of-service attack or a distributed denial-of-service attack.`,
      },
      {
        text: `&emsp;(h) Take any action that may damage or falsify Company rating.`,
      },
      {
        text: `&emsp;(i) Otherwise attempt to interfere with the proper working of Service.`,
      },
    ],
  },
  {
    term_name: 'Analytics',
    children: [
      {
        text: `We may use third-party Service Providers to monitor and analyze the use of our Service.`,
      },
      {
        text: `<b>Google Analytics</b><br/>Google Analytics is a web analytics service offered by Google that tracks and reports website
        traffic. Google uses the data collected to track and monitor the use of our Service. This data
        is shared with other Google services. Google may use the collected data to contextualise and
        personalise the ads of its own advertising network.`,
      },
      {
        text: `For more information on the privacy practices of Google, please visit the Google Privacy
        Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>`,
      },
      {
        text: `We also encourage you to review the Google's policy for safeguarding your data:<a href="hhttps://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.`,
      },
      {
        text: `<b>Firebase</b><br/>Firebase is analytics service provided by Google Inc.`,
      },
      {
        text: `You may opt-out of certain Firebase features through your mobile device settings, such as
        your device advertising settings or by following the instructions provided by Google in their
        Privacy Policy: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>`,
      },
      {
        text: `For more information on what type of information Firebase collects, please visit the Google
        Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>`,
      },
    ],
  },
  {
    term_name: 'No Use By Minors',
    children: [
      {
        text: `Service is intended only for access and use by individuals at least eighteen (18) years old. By
        accessing or using any of Company, you warrant and represent that you are at least eighteen
        (18) years of age and with the full authority, right, and capacity to enter into this agreement
        and abide by all of the terms and conditions of Terms. If you are not at le`,
      },
    ],
  },
  {
    term_name: 'Accounts',
    children: [
      {
        text: `When you create an account with us, you guarantee that you are above the age of 18, and
        that the information you provide us is accurate, complete, and current at all times. Inaccurate,
        incomplete, or obsolete information may result in the immediate termination of your account
        on Service.`,
      },
      {
        text: `You are responsible for maintaining the confidentiality of your account and password,
        including but not limited to the restriction of access to your computer and/or account. You
        agree to accept responsibility for any and all activities or actions that occur under your account
        and/or password, whether your password is with our Service or a third-party service. You must
        notify us immediately upon becoming aware of any breach of security or unauthorized use of
        your account.`,
      },
      {
        text: `You may not use as a username the name of another person or entity or that is not lawfully
        available for use, a name or trademark that is subject to any rights of another person or entity
        other than you, without appropriate authorization. You may not use as a username any name
        that is offensive, vulgar or obscene.`,
      },
      {
        text: `We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel
        orders in our sole discretion.`,
      },
    ],
  },
  {
    term_name: 'Intellectual Property',
    children: [
      {
        text: `Service and its original content (excluding Content provided by users), features and
        functionality are and will remain the exclusive property of r3volutionD AG and its licensors.
        Service is protected by copyright, trademark, and other laws of the United States and foreign
        countries. Our trademarks and trade dress may not be used in connection with any product
        or service without the prior written consent of r3volutionD AG.`,
      },
    ],
  },
  {
    term_name: 'Copyright Policy',
    children: [
      {
        text: `We respect the intellectual property rights of others. It is our policy to respond to any claim
        that Content posted on Service infringes on the copyright or other intellectual property rights
        (“<b>Infringement</b>”) of any person or entity.`,
      },
      {
        text: `If you are a copyright owner, or authorized on behalf of one, and you believe that the
        copyrighted work has been copied in a way that constitutes copyright infringement, please
        submit your claim via email to support@r3volutionD.com, with the subject line: “Copyright
        Infringement” and include in your claim a detailed description of the alleged Infringement as
        detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims"`,
      },
      {
        text: `You may be held accountable for damages (including costs and attorneys' fees) for
        misrepresentation or bad-faith claims on the infringement of any Content found on and/or
        through Service on your copyright.`,
      },
    ],
  },
  {
    term_name: 'DMCA Notice and Procedure for Copyright Infringement Claims',
    children: [
      {
        text: `You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by
        providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3)
        for further detail):`,
      },
      {
        text: `&emsp;(a) an electronic or physical signature of the person authorized to act on behalf of the owner
        of the copyright's interest;`,
      },
      {
        text: `&emsp;(b) a description of the copyrighted work that you claim has been infringed, including the URL
        (i.e., web page address) of the location where the copyrighted work exists or a copy of the
        copyrighted work;`,
      },
      {
        text: `&emsp;(c) identification of the URL or other specific location on Service where the material that you
        claim is infringing is located;`,
      },
      {
        text: `&emsp;(d) your address, telephone number, and email address;`,
      },
      {
        text: `&emsp;(e) a statement by you that you have a good faith belief that the disputed use is not authorized
        by the copyright owner, its agent, or the law;`,
      },
      {
        text: `&emsp;(f) a statement by you, made under penalty of perjury, that the above information in your
        notice is accurate and that you are the copyright owner or authorized to act on the
        copyright owner's behalf.`,
      },
      {
        text: `You can contact our Copyright Agent via email at support@r3volutionD.com`,
      },
    ],
  },
  {
    term_name: 'Error Reporting and Feedback',
    children: [
      {
        text: `You may provide us directly at info@r3volutionD.com with information and feedback
        concerning errors, suggestions for improvements, ideas, problems, complaints, and other
        matters related to our Service (“<b>Feedback</b>”). You acknowledge and agree that: (i) you shall
        not retain, acquire or assert any intellectual property right or other right, title or interest in or
        to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii)
        Feedback does not contain confidential information or proprietary information from you or any
        third party; and (iv) Company is not under any obligation of confidentiality with respect to the
        Feedback. In the event the transfer of the ownership to the Feedback is not possible due to
        applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable,
        irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including
        copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any
        manner and for any purpose.`,
      },
    ],
  },
  {
    term_name: 'Links To Other Web Sites',
    children: [
      {
        text: `Our Service may contain links to third party web sites or services that are not owned or
        controlled by r3volutionD AG.`,
      },
      {
        text: `r3volutionD AG has no control over, and assumes no responsibility for the content, privacy
        policies, or practices of any third party web sites or services. We do not warrant the offerings
        of any of these entities/individuals or their websites.`,
      },
      {
        text: `WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
        POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.`,
      },
    ],
  },
  {
    term_name: 'Disclaimer Of Warranty',
    children: [
      {
        text: `THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
        BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
        EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
        INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY
        AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES
        OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.`,
      },
      {
        text: `NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
        WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
        SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES.
        WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE
        ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES,
        THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES
        WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
        WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT
        AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT
        THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES
        WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.`,
      },
      {
        text: `COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
        OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
        PARTICULAR PURPOSE.`,
      },
      {
        text: `THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
        EXCLUDED OR LIMITED UNDER APPLICABLE LAW.`,
      },
    ],
  },
  {
    term_name: 'Limitation Of Liability',
    children: [
      {
        text: `EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
        DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
        (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF
        LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR
        NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
        CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR
        IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY
        CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS
        AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL
        LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN
        PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
        PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT
        WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES,
        AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE
        DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
        PUNITIVE, INCIDENTAL OR CONSEQUENTI`,
      },
    ],
  },
  {
    term_name: 'Termination',
    children: [
      {
        text: `We may terminate or suspend your account and bar access to Service immediately, without
        prior notice or liability, under our sole discretion, for any reason whatsoever and without
        limitation, including but not limited to a breach of Terms.`,
      },
      { text: `If you wish to terminate your account, you may simply discontinue using Service.` },
      {
        text: `All provisions of Terms which by their nature should survive termination shall survive
      termination, including, without limitation, ownership provisions, warranty disclaimers,
      indemnity and limitations of liability.`,
      },
    ],
  },
  {
    term_name: 'Governing Law',
    children: [
      {
        text: `These Terms shall be governed and construed in accordance with the laws of Lower Saxony,
        Germany without regard to its conflict of law provisions.`,
      },
      {
        text: `Our failure to enforce any right or provision of these Terms will not be considered a waiver of
        those rights. If any provision of these Terms is held to be invalid or unenforceable by a court,
        the remaining provisions of these Terms will remain in effect. These Terms constitute the
        entire agreement between us regarding our Service and supersede and replace any prior
        agreements we might have had between us regarding Service.`,
      },
    ],
  },
  {
    term_name: 'Changes To Service',
    children: [
      {
        text: `We reserve the right to withdraw or amend our Service, and any service or material we provide
        via Service, in our sole discretion without notice. We will not be liable if for any reason all or
        any part of Service is unavailable at any time or for any period. From time to time, we may
        restrict access to some parts of Service, or the entire Service, to users, including registered
        users.`,
      },
    ],
  },
  {
    term_name: 'Amendments To Terms',
    children: [
      {
        text: `We may amend Terms at any time by posting the amended terms on this site. It is your
        responsibility to review these Terms periodically.`,
      },
      {
        text: `Your continued use of the Platform following the posting of revised Terms means that you
        accept and agree to the changes. You are expected to check this page frequently so you are
        aware of any changes, as they are binding on you.`,
      },
      {
        text: `By continuing to access or use our Service after any revisions become effective, you agree to
        be bound by the revised terms. If you do not agree to the new terms, you are no longer
        authorized to use Service.`,
      },
    ],
  },
  {
    term_name: 'Waiver And Severability',
    children: [
      {
        text: `No waiver by Company of any term or condition set forth in Terms shall be deemed a further
        or continuing waiver of such term or condition or a waiver of any other term or condition, and
        any failure of Company to assert a right or provision under Terms shall not constitute a waiver
        of such right or provision.`,
      },
      {
        text: `If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be
        invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited
        to the minimum extent such that the remaining provisions of Terms will continue in full force
        and effect.`,
      },
    ],
  },
  {
    term_name: 'Acknowledgement',
    children: [
      {
        text: `BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
        THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
        THEM.`,
      },
    ],
  },
  {
    term_name: 'Contact Us',
    children: [
      {
        text: `Please send your feedback, comments, requests for technical support:<br/>
        By email: info@r3volutionD.com.`,
      },
    ],
  },
]

const TermsConditions = () => {
  const history = useHistory()
  return (
    <div className="privacy-policy-container">
      <Row>
        <Col>
          <NavBar hideFiles hideBilling />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <Header onBack={() => history.goBack()} />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <Typography variant="h4" align="center" className="!font-semibold heading">
            TERMS OF SERVICE
          </Typography>
          <Typography variant="body2" align="left" style={{ marginBottom: '20px' }}>
            Effective date: 02/15/2022
          </Typography>
          <ol type="1">
            {TermsConditionData.map((termCategory, categoryIndex) => (
              <li>
                <Typography
                  variant="body2"
                  align="left"
                  style={{ marginBottom: '20px', textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  {termCategory.term_name}
                </Typography>
                {termCategory.children.map((content) => (
                  <Typography variant="body2" align="justify" style={{ marginBottom: '20px' }}>
                    {parse(content.text)}
                  </Typography>
                ))}
              </li>
            ))}
          </ol>
        </Col>
      </Row>
    </div>
  )
}

export default TermsConditions
