import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { HiOutlineChevronDoubleRight } from 'react-icons/all'
import LottieAnimation from './loading/loading'
import home from './loading/loading.json'
import app from '../../firebase'
import { useAuth } from '../../contexts/AuthContext'
import 'firebase/compat/firestore'
import Card from '../UI/Card'
import { Button, Typography } from '@mui/material'
import ReduxStore from 'Redux/Store'
import { Link } from 'react-router-dom'

function EmptyState() {
  const downloadApp = () => {
    window.open(process.env.REACT_APP_PHOTO_APP)
  }

  return (
    <div style={{ textAlign: 'center', width: '300px', margin: 'auto' }}>
      <div style={{ fontSize: '28px', margin: '10px', marginTop: '50%' }}>No Scan yet...</div>
      <div style={{ color: '#888' }}>
        Download our 3D scan app to scan your model. After uploading your 3D scan in the app you can
        find the model here.
      </div>
      <Button
        color="dark"
        variant="contained"
        className="mt-5"
        onClick={downloadApp}
        style={{ width: '200px' }}
      >
        Download App
      </Button>
    </div>
  )
}
export default function ThreeDView(props) {
  const drop = (e) => props.loadFiles(e)
  const db = app.firestore()
  const { currentUser } = useAuth()

  const canvasRoot = useRef()
  const viewport = useRef()

  const [docs, setDocs] = useState([])
  useEffect(() => {
    db.collection('models')
      .doc(currentUser.uid)
      .collection('data')
      .get()
      .then((res) => {
        setDocs(() =>
          res.docs
            .filter((doc) => doc.data().paid && doc.data().objPath)
            .map((doc) => ({ data: doc.data(), id: doc.id }))
        )
      })
  }, [currentUser, db])

  const onRemove = useCallback(
    (id) => {
      setDocs(docs.filter((doc) => doc.id !== id))
    },
    [docs]
  )

  const cards = useMemo(() => {
    return docs.map((doc) => {
      if (!doc.data) return null
      const date = new Date(doc.data.timestamp.seconds * 1000)
      return (
        <Card
          key={doc.id}
          id={doc.id}
          previewImageURL={doc.data.previewImageURL}
          name={doc.data.name}
          processed={doc.data.processed}
          modelPath={doc.data.objPath}
          time={date.toString().substring(4, 15)}
          remove={() => onRemove(doc.id)}
        />
      )
    })
  }, [docs, onRemove])

  const handleDisplayFiles = () => {
    ReduxStore.dispatch({ type: 'setLocalFileRead', data: false })
  }

  return (
    <div style={{ minHeight: '80vh' }}>
      <div className="canvasWrapper" id="canvas_wrapper" onDrop={drop}>
        <HiOutlineChevronDoubleRight onClick={props.handleShow} className="chevron-icon" />
        <div className="canvas-root" ref={canvasRoot}>
          <canvas id="canvas3d"></canvas>
        </div>
        <div id="viewport" ref={viewport}>
          <canvas id="canvas" />
        </div>
        {props.localFileRead && (props.loadState === 'preload' || props.loadState === undefined) && (
          <div className="uploadInput">
            <div>
              <label htmlFor="files" className="uploadModel">
                Upload Model
              </label>
              <input
                type="file"
                id="files"
                style={{ display: 'none' }}
                onChange={props.loadFiles}
                multiple
              />
              <Typography align="center" color="hint.text" variant="h6">
                or
              </Typography>
              <Link to="/#files" className="nodecoration">
                <label onClick={handleDisplayFiles} className="uploadModel px-4 py-2">
                  Choose 3D Scan
                </label>
              </Link>
            </div>
          </div>
        )}
        {props.loadState === 'loading' && (
          <div id="loading">
            <LottieAnimation lotti={home} height={300} width={300} />
          </div>
        )}
        <div id="labelDiv"></div>
      </div>
      {!props.localFileRead && props.loadState === 'preload' && (
        <div id="cards">{cards.length > 0 ? cards : <EmptyState />}</div>
      )}
      <input type="file" id="fileopen" multiple style={{ display: 'none' }} />
      <input type="file" id="backgroundopen" style={{ display: 'none' }} />
      <input type="file" id="alphaopen" style={{ display: 'none' }} />
      <input type="file" id="textureopen" style={{ display: 'none' }} />
      <input type="file" id="matcapopen" style={{ display: 'none' }} />
    </div>
  )
}
