import React, { useRef, useState } from 'react'
import { Form, Card, Alert } from 'react-bootstrap'
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useAuth } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import r3dent from '../../assets/img/r3dent_big.svg'
import 'firebase/compat/firestore'
import app from '../../firebase'

export default function Signup() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [agree, setAgree] = useState(false)
  const history = useHistory()
  const db = app.firestore()

  async function handleSubmit(e) {
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match')
    }

    try {
      setError('')
      setLoading(true)
      const newUser = await signup(emailRef.current.value, passwordRef.current.value)

      addUser(newUser.user.uid)
    } catch {
      setError('Failed to create an account')
    }

    setLoading(false)
  }

  const addUser = async (uID) => {
    const userData = {
      email: emailRef.current.value,
      userId: uID,
      r3Dent: true,
    }
    let saveUserData = db.collection('users').doc(uID)

    try {
      await saveUserData
        .set(userData)
        .then(history.push(`/address/${uID}`))
        .catch((err) => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  const handlePreventDefault = (event) => {
    event.preventDefault()
  }

  return (
    <section className="signupForm shadow-none">
      <div
        style={{
          textAlign: 'center',
          height: '13vh',
          fontSize: '40px',
          fontWeight: '600',
          display: 'flex',
          justifyContent: 'center',
          color: '#fff',
        }}
      >
        <img src={r3dent} style={{ width: '200px' }} alt="logo" />
      </div>
      <Card className="authCard">
        <Card.Body>
          <Typography variant="h4" align="left" className="mb-4 cardheader">
            Get Started
          </Typography>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="email@email.com" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label className="mt-3">Password</Form.Label>
              <Form.Control type="password" placeholder="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label className="mt-3">Repeat Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="password"
                ref={passwordConfirmRef}
                required
              />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <FormControlLabel
                labelPlacement="end"
                control={<Checkbox />}
                size="small"
                onChange={() => {
                  setAgree(!agree)
                }}
                className="mb-4"
                label={
                  <p
                    className="no-underline"
                    style={{ marginBottom: -12, cursor: 'auto' }}
                    onClick={handlePreventDefault}
                  >
                    I agree to r3volutionD's
                    <Link className="nodecoration" to="/terms-conditions">
                      {' '}
                      terms & conditions{' '}
                    </Link>
                    and
                    <Link className="nodecoration" to="/privacy-policy">
                      {' '}
                      privacy policy{' '}
                    </Link>
                  </p>
                }
              />
            </Form.Group>
            <Button
              color="dark"
              variant="contained"
              disabled={loading || !agree}
              className="w-100 sign-up-button"
              type="submit"
            >
              Signup
            </Button>
          </Form>
          <div className="w-100 link-login" style={{ textDecoration: 'none' }}>
            Already a member?{' '}
            <Link className="nodecoration" to="/login">
              Log In
            </Link>
          </div>
        </Card.Body>
      </Card>
      <div className="made-by">
        made by
        <br />
        <img src={logo} alt="logo img"></img>
      </div>
    </section>
  )
}
