import * as THREE from 'three'
import { TransformControls } from '../controls/transformControls'
import crossImg from '../../../assets/img/cross.png'
import rightImg from '../../../assets/img/right.png'
import { Slice } from './slice.js'

export default class Cut {
  constructor(scene, camera, canvas, orbitCtrl) {
    this.scene = scene
    this.camera = camera
    this.canvas = canvas
    this.orbitCtrl = orbitCtrl
    this.control = new TransformControls(this.camera, this.canvas)
    this.control.visible = false
    this.control.setSize(2)
    this.control.setSpace('local')
    this.control.addEventListener('dragging-changed', (e) => {
      this.orbitCtrl.enabled = !e.value
    })

    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()
    this.cutState = false

    this.canvas.addEventListener('pointermove', (e) => {
      const offsetLeft = this.getOffset(this.canvas).left
      const offsetTop = this.getOffset(this.canvas).top
      var width = this.canvas.clientWidth
      var height = this.canvas.clientHeight
      this.mouse.x = ((e.clientX - offsetLeft) / width) * 2 - 1
      this.mouse.y = -((e.clientY - offsetTop) / height) * 2 + 1
    })

    this.meshes = [] // used for cut reference
    this.Slice = new Slice()
  }

  showCutPlane(targetMesh) {
    this.hideCutPlane()
    this.cutState = true

    this.targetMesh = targetMesh

    const rightSymbolMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(10, 10),
      new THREE.MeshStandardMaterial({
        depthTest: false,
        side: THREE.DoubleSide,
        map: new THREE.TextureLoader().load(rightImg),
        transparent: true,
      })
    )
    rightSymbolMesh.geometry.translate(0, 5, 0)
    rightSymbolMesh.geometry.rotateX(Math.PI / 2)
    rightSymbolMesh.position.set(40, 0, 0)
    this.meshes.push(rightSymbolMesh) // 0th

    const crossSymbolMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(10, 10),
      new THREE.MeshStandardMaterial({
        depthTest: false,
        side: THREE.DoubleSide,
        map: new THREE.TextureLoader().load(crossImg),
        transparent: true,
      })
    )
    crossSymbolMesh.geometry.translate(0, -5, 0)
    crossSymbolMesh.position.set(40, 0, 0)
    crossSymbolMesh.geometry.rotateX(Math.PI / 2)
    this.meshes.push(crossSymbolMesh) // 1st

    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(110, 100),
      new THREE.MeshStandardMaterial({
        color: 0xff80ff,
        transparent: true,
        opacity: 0.8,
        side: THREE.DoubleSide,
      })
    )
    plane.rotation.x = -Math.PI / 2
    plane.position.y = 0.001
    plane.name = 'plane'
    plane.add(rightSymbolMesh, crossSymbolMesh)
    this.meshes.push(plane) //5th
    this.cutPlane = plane
    this.scene.add(plane)
    const s =
      new THREE.Box3().setFromObject(this.targetMesh).getSize(new THREE.Vector3()).length() / 70
    this.cutPlane.scale.multiplyScalar(s)
    this.control.attach(this.cutPlane)
    this.scene.add(this.control)
  }

  hideCutPlane() {
    this.scene.remove(this.control)
    this.cutState = false
    if (this.meshes.length > 0) {
      this.meshes.forEach((m) => {
        m.material.dispose()
        m.geometry.dispose()
        this.scene.remove(m)
      })
    }

    if (this.controlMesh) {
      this.controlMesh.geometry.dispose()
      this.controlMesh.material.dispose()
      this.scene.remove(this.controlMesh)
    }
    this.meshes = []
  }

  setTransformCtrl(mode) {
    if (this.control) this.control.setMode(mode)
  }

  getPlane() {
    var worldPosition = new THREE.Vector3()
    var worldQuaternion = new THREE.Quaternion()
    this.cutPlane.getWorldPosition(worldPosition)
    this.cutPlane.getWorldQuaternion(worldQuaternion)

    var plane = new THREE.Plane()
    var normal = new THREE.Vector3()
    var point = new THREE.Vector3()

    normal.set(0, 0, 1).applyQuaternion(worldQuaternion)

    point.copy(worldPosition)

    plane.setFromNormalAndCoplanarPoint(normal, point)
    return plane
  }

  mouseup(object) {
    const plane = this.getPlane()
    return new Promise((resolve, reject) => {
      var newGeo = this.Slice.sliceGeometry(object, plane, false)
      if (newGeo) {
        resolve(newGeo)
      } else {
        reject('Unable to cut the model')
      }
    })
  }

  getOffset(el) {
    var _x = 0
    var _y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft
      _y += el.offsetTop - el.scrollTop
      el = el.offsetParent
    }
    return { top: _y, left: _x }
  }
}
