import * as React from 'react'
import PropTypes from 'prop-types'
import Slider, { SliderThumb } from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#555A5E',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    borderRadius: '8px',
    height: 27,
    width: 27,
    backgroundColor: '#555A5E',
    border: '1px solid currentColor',
    boxShadow: '0 0 0 4px rgba(85,90,94, 0.2) !important',
    '&:hover': {
      boxShadow: '0 0 0 4px rgba(85,90,94, 0.2)',
    },
    '&:active': {
      boxShadow: '0 0 0 14px rgba(85,90,94, 0.2)',
    },
    '& .cutom-bar': {
      height: 9,
      width: 1,
      backgroundColor: '#FFFFFF',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}))

function AirbnbThumbComponent(props) {
  const { children, ...other } = props
  return (
    <SliderThumb {...other}>
      {children}
      <span className="cutom-bar" />
      <span className="cutom-bar" />
      <span className="cutom-bar" />
    </SliderThumb>
  )
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
}

const CustomizedSlider = (props) => {
  const { label, ...rest } = props
  return (
    <>
      <Box sx={{ m: 3 }} />
      {label && (
        <Typography gutterBottom align="left">
          {label}
        </Typography>
      )}
      <StyledSlider components={{ Thumb: AirbnbThumbComponent }} {...rest} />
    </>
  )
}

export default CustomizedSlider

CustomizedSlider.defaultProps = {
  value: 'a',
  label: '',
}
