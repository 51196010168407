import { createTheme, responsiveFontSizes } from '@mui/material/styles'

/**
 * Theme
 */
const commonTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      contrastText: '#FFFFFF',
    },
    appBar: {},
    appBarButton: {
      main: '#00AEF0',
      contrastText: '#FFFFFF',
    },
    dark: {
      main: '#2D3338',
      contrastText: '#FFFFFF',
    },
    secondaryBlack: {
      main: '#555A5E',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: `"Poppins", sans-serif -webkit-font-smoothing: antialiased -moz-osx-font-smoothing: grayscale !important`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          textTransform: 'inherit',
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '90px !important',
        },
      },
    },
  },
})

// Do your common overrides here
/**
 * Typography - body1
 */
/**
 * Typography - caption
 */
/**
 *  ** THIS SHOULD BE LAST **
 * Root Theme
 */
const rootTheme = responsiveFontSizes(commonTheme)

export default rootTheme
