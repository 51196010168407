import { RENDERER } from './renderer'
import 'firebase/compat/firestore'

let options = undefined
let renderer = undefined

export function main(args) {
  options = args
  renderer = new RENDERER(options)
}

export function refresh(args, mode) {
  renderer.updateAttrs(args, mode)
}

export function clearScene() {
  if (renderer.threedManager) renderer.threedManager.clearObjects()
}
export function loadMesh(file) {
  if (renderer.threedManager) renderer.threedManager.loadMesh(file)
}

export function loadFile(file, fileType) {
  if (renderer.threedManager) renderer.threedManager.loadFile(file, fileType)
}

export function loadFileFromUrl(url) {
  if (renderer.threedManager) renderer.threedManager.loadFileFromUrl(url)
}

export function clearObjects() {
  if (renderer.threedManager) renderer.threedManager.clearObjects()
}

export function exportSTL() {
  renderer.threedManager.exportSTL()
}

export function viewMode() {
  if (renderer.threedManager) renderer.threedManager.viewMode()
}

export function centerModel() {
  if (renderer.threedManager) renderer.threedManager.setcontent(undefined, false)
}

export function measureMode() {
  if (renderer.threedManager) renderer.threedManager.measureMode()
}

export function remesh() {
  if (renderer.threedManager) renderer.threedManager.remesh()
}

export function hollowMode() {
  if (renderer.threedManager) renderer.threedManager.hollowMode()
}

export function hollow() {
  if (renderer.threedManager) renderer.threedManager.hollow()
}

export function changeRadius(value) {
  if (renderer.threedManager) renderer.threedManager.changeRadius(value)
}

export function changeLength(value) {
  if (renderer.threedManager) renderer.threedManager.changeLength(value)
}

export function fillAddMode(value) {
  if (renderer.threedManager) renderer.threedManager.fillAddMode(value)
}

export function setBrush(value) {
  if (renderer.threedManager) renderer.threedManager.setBrush(value)
}

export function cutModel() {
  if (renderer.threedManager) renderer.threedManager.cutModel()
}

export function prepareCut() {
  if (renderer.threedManager) renderer.threedManager.prepareCut()
}

export function setTransformMode(mode) {
  if (renderer.threedManager) renderer.threedManager.setTransformMode(mode)
}

export function setLoadState(state) {
  if (renderer.threedManager) renderer.threedManager.setLoadState(state)
}

export function removeCutGadgets() {
  if (renderer.threedManager) renderer.threedManager.removeCutGadgets()
}

export function scale(realScale) {
  if (renderer.threedManager) renderer.threedManager.scale(realScale)
}

export function initControl(isScale = false) {
  if (renderer.threedManager) renderer.threedManager.initControl(isScale)
}

export function undo() {
  if (renderer.threedManager) renderer.threedManager.undo()
}

export function loadObjFiles(buffers) {
  if (renderer.threedManager) renderer.threedManager.loadObjFiles(buffers)
}

export function loadObjFiles_firebase(paths) {
  if (renderer.threedManager) renderer.threedManager.loadObjFiles_firebase(paths)
}

export function crop() {
  if (renderer.threedManager) renderer.threedManager.crop()
}

export function cropMode() {
  if (renderer.threedManager) renderer.threedManager.cropMode()
}

export function cropFinish() {
  if (renderer.threedManager) renderer.threedManager.cropFinish()
}

export function handleCropValueChange(value) {
  if (renderer.threedManager) renderer.threedManager.handleCropValueChange(value)
}

export function setControlMode(value) {
  if (renderer.threedManager) renderer.threedManager.setControlMode(value)
}

export function rotateModel() {
  if (renderer.threedManager) renderer.threedManager.rotateModel()
}
