import Defaults from './Defaults'

const Reducer = (state = Defaults, action) => {
  switch (action.type) {
    case 'setLoadState':
      return {
        ...state,
        LoadState: action.data,
      }
    case 'setCropValue':
      return {
        ...state,
        CropValue: action.data,
      }
    case 'setLocalFileRead':
      return {
        ...state,
        LocalFileRead: action.data,
      }
    default:
      break
  }
  return state
}

export default Reducer
